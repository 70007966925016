import React, { useState } from 'react';
import "./Team.css";
// import { AddressEditForm } from '../EditAddress_copy/EditAddress';
// import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import divider3 from "../../icons/Gerator/dashboard/divider3.svg"
import placeholder2 from '../../icons/Gerator/profile_settings/placeholder2.svg';
import placeholder3 from '../../icons/Gerator/profile_settings/placeholder3.svg';

import trend_down_02 from "../../icons/Gerator/profile_settings/trend_down_02.svg";
import trend_up_02 from "../../icons/Gerator/profile_settings/trend_up_02.svg"
import thumbs_up from "../../icons/Gerator/profile_settings/thumbs_up.svg";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, onAuthStateChanged } from '../Login/FirebaseClient';
import SkeletonListingLoaderComponent from '../../components/SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';
import { NoListingFound } from '../../components/NoListing/NoListingFound';
import { useRef } from 'react';
import { useDeleteAddressMutation, useGetUserDashboardAddressesQuery, useLazyGetAddressesQuery } from '../../api/AddressAPI';
import dotsvertical from "../../icons/Gerator/contacts/dotsvertical.svg";
import arrowdown from "../../icons/Gerator/contacts/arrowdown.svg";
import markerpin02 from "../../icons/Gerator/contacts/markerpin02.svg";
import help_icon from "../../icons/Gerator/contacts/help_icon.svg";
import arrowdown1 from '../../icons/Gerator/dashboard/arrowdown1.svg';
import badge_paid from "../../icons/Gerator/dashboard/Badge_Paid.svg";


import { useEffect } from 'react';
import { NoAddress } from '../../components/NoAddress/NoAddress';
import { NothingFoundMessage } from '../../components/NothingFoundMessage/NothingFoundMessage';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { NoAccessTeam } from '../../components/NoAccessTeam/NoAccessTeam';
import TeamMemberTableTabs from '../../components/TeamMemeberTableTabs/TeamMemberTableTabs';
import { AddTeamSlideOutForm } from '../../components/AddTeamSlideOutForm/AddTeamSlideOutForm';
import { NoAccessBusiness } from '../../components/NoAccessBusiness/NoAccessBusiness';
import { useGetTeamMemberQuotaQuery, useLazyGetTeamMemberPageAccessQuery } from '../../api/MemberAPI';
import { ErrorShowComponent } from '../../components/ErrorShowComponent/ErrorShowComponent';
import { NoAccessTeamNormal } from '../../components/NoAccessTeamNormal/NoAccessTeamNormal';
import { StageSlideOutForm } from '../../components/StageSlideOutForm/StageSlideOutForm';
import { useGetMemberInviteUserTypeTaxQuery } from '../../api/TaxonomyFormAPI';

export const Team = ({ currentUser, currentUserMeta }) => {

  // const [currentPlanTeamMemberLimit, setCurrentPlanTeamMemberLimit] = useState("");
  const [currentRemainingTeamMember, setCurrentRemainingTeamMember] = useState("");
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [userType, setUserType] = useState(null);

  const { data: teamMemberQuotaData, isSuccess: isTeamMemberQuotaSuccess, error: teamMemberQuotaError, isLoading: isTeamMemberQuotaLoading, isError: isTeamMemberQuotaError } = useGetTeamMemberQuotaQuery();

  const toggleForm = () => {
    setIsFormOpen(!isFormOpen);
  };

  //Offset to load data when click load more
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  // const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  // const [currentUser, setCurrentUser] = useState("");
  // const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [totalCount, setTotalCount] = useState("");
  // const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  // const [totalCountWantedService, settotalCountWantedService] = useState("");
  // const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");
  // const [activePostStatus, setActivePostStatusFilter] = useState(null);

  //Query to get team member page access right
  const [triggerGetTeamMemberPageAccess, { data, isSuccess, isError, isLoading }] = useLazyGetTeamMemberPageAccessQuery();

  // Delete query for address
  const [deleteAddress] = useDeleteAddressMutation();

  //Getting taxonomy for invite form UserType
  const userTypeTax = useGetMemberInviteUserTypeTaxQuery();

  useEffect(() => {

    const allSuccess = [userTypeTax.isSuccess].every(Boolean);

    if (allSuccess) {
      setUserType(userTypeTax);
    }
  }, [
    userTypeTax.isSuccess, userTypeTax.data,
  ]);

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  // const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  const handleDelete = async (id) => {
    try {

      const response = await deleteAddress(id).unwrap();

      if (response.status) {
        // setShowSuccessMessage(response.message)
        // await new Promise(resolve => setTimeout(resolve, 1500));
        // Reseting form
        window.location.reload();
      }
    } catch (error) {
      console.error(`Failed to delete Address `, error);
    }
  };


  useEffect(() => {
    if (teamMemberQuotaData) {
      console.log(teamMemberQuotaData);

      setCurrentRemainingTeamMember(teamMemberQuotaData.data);
    }
  }, [teamMemberQuotaData])



  // const toggleDropdown = () => {
  //   setDropdownVisible(!isDropdownVisible);
  // }

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setActiveThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setActiveThreeDotId(id); // Open the clicked one
  //   }
  // };

  // const handleSearch = (term) => {
  //   setSearchTerm(term);
  // };


  //Set value for sortby selected option on button text
  // const handleOptionSelect = (option) => {
  //   if (option.value !== sortBy) {
  //     // setSortBy(option.value);
  //     setOffset(0); // Reset offset when sort option changes
  //   }
  //   setSelectedSortOption(option);
  //   setDropdownVisible(false);
  // };

  //DashbaordFilterButtonGroup component to get data
  // const handleFilterClick = (filterLabel) => {
  //   if (activePostStatus === filterLabel) {
  //     setSortBy('date_desc')
  //     setSelectedSortOption('')
  //     setActivePostStatusFilter(null);
  //   } else {
  //     setSortBy('date_desc')
  //     setSelectedSortOption('')
  //     setActivePostStatusFilter(filterLabel);
  //   }
  //   setOffset(0); // Reset offset when changing filters
  // };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.addresses.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setDropdownVisible(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);


  //Onclick listener for threedots dropdown
  // useEffect(() => {
  //   const handleThreeDotClickOutside = (event) => {
  //     if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
  //       setActiveThreeDotId(null); // Close any open dropdown when clicking outside
  //     }
  //   };

  //   document.addEventListener("mousedown", handleThreeDotClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleThreeDotClickOutside);
  //   };
  // }, []);

  // // Listen for tab visibility changes
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.visibilityState === 'visible') {
  //       refetch();
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [refetch]);


  //Success true then pause to show sekelton layout
  // useEffect(() => {
  //   if (isSuccess) {
  //     // Set totalcount in listing counter layout
  //     setTotalCount(data.totalCount);
  //     // settotalCountExpired(data.totalCountExpired);
  //     // Set timeout to delay the loading completion
  //     const timer = setTimeout(() => {
  //       setLoadingComplete(true);
  //     }, 1000); // 1 second

  //     // Cleanup timer on component unmount
  //     return () => clearTimeout(timer);
  //   }
  // }, [isSuccess]);

  // useEffect(() => {
  //   // const auth = getAuth();

  //   // Listen for authentication state change
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is authenticated
  //       setCurrentUser(user);
  //     } else {
  //       // User is not authenticated, redirect to login
  //       navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
  //     }
  //   });

  //   return () => unsubscribe(); // Cleanup listener on component unmount
  // }, [navigate]);

  useEffect(() => {

    if (currentUserMeta && currentUserMeta.isUserEmailDomainPrivate) {
      triggerGetTeamMemberPageAccess();
    }
  }, [currentUserMeta])



  //Ensuring both user and meta is loaded then second if condition will work
  //When domain is private this will show NoAccessTeam Banner
  if (currentUser && currentUserMeta) {
    if (currentUserMeta?.isUserEmailDomainPrivate === false) {
      return <NoAccessBusiness path={currentUser.email} />
    }
  }

  if (isError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  // if (isLoading) {
  //   content = <SkeletonListingLoaderComponent />;
  // } else if (loadingComplete && isSuccess) {

  //   if (data.addresses.length > 0) {
  //     // dataLength = data.addresses.length;
  //     content = data.addresses.map((address, index) =>
  //     (

  //       <>

  //       </>
  //     ))
  //   }
  //   else {
  //     //   content = (

  //     //     <NothingFoundMessage text="Your company doesn't have an address yet ?!" text1="Let's start the process ?" text2="You need to create an address for your business,or be related one." />
  //     //     // <NoAddress />
  //     //   )
  //   }

  // } else if (!loadingComplete && isSuccess) {
  //   content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  // }

  if (isSuccess) {
    if (data && data.status) {
      content = (
        <div className="team-display-form-content-wrapper">
          <div className="team-display-form-content">
            <div className="team-display-toggle-header-parent">
              <div className="team-display-toggle-header">
                <div className="team-display-toggle-title">My Team</div>

                <div className="team-display-supporting-text2">
                  Unleash your team's powers to drive growth.
                </div>


              </div>
              <div className="team-display-toggle-controls">
                {/* <div className="team-display-rectangle-group">
                <div className="team-display-frame-item"></div>
                <div className="team-display-toggle-base">
                  <div className="team-display-button6"></div>
                </div>
                <div className="team-display-switch-label">Explorer mode</div>
                <div className="team-display-supporting-text3">
                  Save my login details for next time.
                </div>
              </div> */}


                {
                  ((currentUserMeta.userPlan === 1 || currentUserMeta.userPlan === 14) && currentUserMeta.userType === 2) && (
                    (
                      <div className="team-display-toggle-supporting-text">
                        <>
                          <button className="team-display-button7"
                            onClick={() => window.location.href = '/dashboard/settings/plan'}>
                            <img
                              className="team-display-placeholder-icon"
                              alt=""
                              src="/profile_settings/placeholder2.svg"
                            />

                            <div className="team-display-button-labels">Upgrade</div>
                            <img
                              className="team-display-placeholder-icon"
                              alt=""
                              src="/profile_settings/placeholder2.svg"
                            />
                          </button>
                          <div className="team-display-supporting-text4">
                            To manage team members
                          </div>
                        </>
                      </div>
                    )
                  )
                }

                {
                  (currentUserMeta.userPlan > 1 && currentUserMeta.userPlan < 14) && (
                    (currentUserMeta.userType === 2 || currentUserMeta.userType === 4) && (
                      (currentRemainingTeamMember > 0) ?
                        <div className="team-display-toggle-supporting-text">

                          <button className="team-display-button8"
                            onClick={toggleForm}
                          >
                            <img
                              className="team-display-plus-icon1"
                              alt=""
                              src={plus}
                            />

                            <div className="team-display-text49"> Invite</div>
                            <img
                              className="team-display-placeholder-icon"
                              alt=""
                              src={placeholder3}
                            />
                          </button>

                          {isFormOpen && <AddTeamSlideOutForm onClose={toggleForm} domain={data.domain} userType={userType} />}

                          <div className="team-display-supporting-text4">
                            {currentRemainingTeamMember} seats left
                          </div>
                        </div>
                        : <div className="team-display-toggle-supporting-text">
                          <>
                            <button className="team-display-button8"
                              onClick={() => window.location.href = '/dashboard/settings/plan'}>
                              <img
                                className="team-display-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder2.svg"
                              />

                              <div className="team-display-text49">Upgrade</div>
                              <img
                                className="team-display-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder2.svg"
                              />
                            </button>
                            <div className="team-display-supporting-text4">
                              {currentRemainingTeamMember} seats left
                            </div>
                          </>
                        </div>
                    )
                  )
                }

              </div>
            </div>
            <div className="team-display-divider-container">
              <img
                className="team-display-divider-icon3"
                alt=""
                src={divider3}
              />
            </div>


            {/* <NoAccessTeam/> */}

            <div className="team-display-section-wrapper">
              <div className="team-display-section1">
                <div className="team-display-container1">
                  <div className="team-display-filters-bar">
                    <div className="team-display-content17">
                      <div className="team-display-dropdowns">

                        <TeamMemberTableTabs currentUser={currentUser} currentUsermeta={currentUserMeta} />

                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      )
    } else {
      content = <NoAccessTeamNormal />
    }
  }


  if (currentUser && currentUserMeta) {
    return (
      <>
        <Helmet>
          <title>Settings - Team</title>
        </Helmet>
        {content}
      </>

    )
  }

}