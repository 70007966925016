import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { auth, app, getAuth } from '../screens/Login/FirebaseClient';
import Cookies from 'js-cookie';
import { decryptUserData, decryptUserData, encryptData, } from '../helper/AESHelper';


export const UsermetaAPI = createApi({
    reducerPath: 'usermetas',
    tagTypes: ['usermetas'],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: async (headers) => {
            let idToken;

            // Check if the user is available
            const user = auth.currentUser;

            if (user) {
                // Get the ID token from Firebase
                idToken = await user.getIdToken();
                if (idToken) {
                    console.log("Got idToken using auth.currentUser");
                }

            }

            if (idToken) {
                headers.set('Authorization', `Bearer ${idToken}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        addUserProfile: builder.mutation({
            // query: (body) => {
            //     // Encrypt request body
            //     console.log(body);
            //     const encryptedData = encryptData(body);
            //     console.log(encryptedData);
            //     return {
            //         url: '/add-user-profile',
            //         method: 'POST',
            //         // body: body,
            //         body: JSON.stringify({ encryptedData }),
            //         headers: { 'Content-Type': 'application/json' },
            //     };
            // },
            query: (body) => {
                return {
                    url: '/add-user-profile',
                    method: 'POST',
                    body: body,
                };
            },
            // transformResponse: (response) => {
            //     // Decrypt response data
            //     // console.log(response.data);
            //     return decryptUserData(response);
            // },
            invalidatesTags: ['usermetas'],
        }),
        addUserProfileForMember: builder.mutation({
            query: (body) => {
                return {
                    url: '/add-user-profile-member',
                    method: 'POST',
                    body: body,
                };
            },
            // transformResponse: (response) => {
            //     // Decrypt response data
            //     // console.log(response.data);
            //     return decryptUserData(response);
            // },
            invalidatesTags: ['usermetas'],
        }),
        editUserProfile: builder.mutation({
            query: (body) => {
                // console.log(body);
                // const encryptedData = encryptData(body);
                // console.log(encryptedData);
                return {
                    url: `/edit-user-profile`,
                    method: 'PUT',
                    body: body,
                    // headers: { 'Content-Type': 'application/json' },
                };
            },
            // transformResponse: (response) => {
            //     // Decrypt response data
            //     return decryptUserData(response);
            // },
            invalidatesTags: ['usermetas']

        }),
        getUserCountry: builder.query({
            query: () => ({
                url: 'get-user-country',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                console.log(response);
                return decryptUserData(response);
            },
            providesTags: ['usermetas'],
        }),
        getUserProfile: builder.query({
            query: () => ({
                url: 'get-user-profile',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                console.log(response);
                return decryptUserData(response);
            },
            providesTags: ['usermetas'],
        }),
        getUserProfileWithTaxName: builder.query({
            query: () => ({
                url: 'get-user-profile-tax-name',
                // Encrypt data if needed, but this endpoint is typically for fetching data
                headers: { 'Content-Type': 'application/json' },
            }),
            transformResponse: (response) => {
                // Decrypt response data
                console.log(response);
                return decryptUserData(response);
            },
            providesTags: ['usermetas'],
        }),
        updateWizardAndPlan: builder.mutation({
            query: (body) => {
                // Encrypt request body
                console.log(body);
                const encryptedData = encryptData(body);
                console.log(encryptedData);
                return {
                    url: '/update-wizard-plan',
                    method: 'PUT',
                    // body: body,
                    body: JSON.stringify({ encryptedData }),
                    headers: { 'Content-Type': 'application/json' },
                };
            },
            transformResponse: (response) => {
                // Decrypt response data
                // console.log(response.data);
                return decryptUserData(response);
            },
            invalidatesTags: ['usermetas'],
        }),

    }),
});

export const {
    useAddUserProfileMutation,
    useAddUserProfileForMemberMutation,
    useEditUserProfileMutation,
    useUpdateWizardAndPlanMutation,
    useLazyGetUserProfileQuery,
    useGetUserProfileQuery,
    useGetUserProfileWithTaxNameQuery,
    useLazyGetUserCountryQuery
} = UsermetaAPI;
