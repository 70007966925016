import React, { useEffect, useRef, useState } from 'react';
// import "./EditBusiness.css"
// import { Footer } from '../../components/Footer/Footer';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { decryptErrorData } from '../../helper/AESHelper';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import { useLazyGetUserQuery } from '../../api/Login&RegisterAPI';
// import { Loader } from '../../components/Loader';
// import { auth, onAuthStateChanged } from '../../Login/FirebaseClient';
// import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
// import { Sidebar } from '../../components/Sidebar/Sidebar';
// import { Footer } from '../../components/Footer/Footer';
// import { Navbar1 } from '../../components/Navbar1/Navbar1';
import "./BusinessFormView.css";

// import { Link } from 'react-router-dom';
// import { useLazyFindGetBusinessQuery, useLazyFindGetBusinessWithoutTaxQuery } from '../../api/BusinessAPI';
// import divider from '../../icons/Gerator/profile_settings/divider.svg';
// import divider1 from '../../icons/Gerator/profile_settings/divider1.svg';
import divider2 from '../../icons/Gerator/profile_settings/divider2.svg';
import lock04 from '../../icons/Gerator/archive_page/lock04.svg';
import featured_icon from '../../icons/Gerator/profile_settings/featured-icon.svg';
// import dotsvertical from '../../icons/Gerator/profile_settings/dotsvertical.svg';
// import markerpin02 from '../../icons/Gerator/profile_settings/markerpin02.svg';
// import bookmarkcheck from '../../icons/Gerator/profile_settings/bookmarkcheck.svg';
// import arrowupright from '../../icons/Gerator/business_page/arrowupright.svg';
import divider from '../../icons/Gerator/profile_settings/divider.svg';
// import homeline from '../../icons/Gerator/account_setup_profile_subscription/homeline.svg';
// import searchlg from '../../icons/Gerator/profile_settings/searchlg.svg';
// import chevronright from '../../icons/Gerator/profile_settings/chevronright.svg';
import placeholder1 from '../../icons/Gerator/profile_settings/placeholder1.svg';
import page from '../../icons/Gerator/add_form/page.svg';
import { auth, onAuthStateChanged } from '../../screens/Login/FirebaseClient';
// import homeline1 from '../../icons/Gerator/profile_settings/homeline-1.svg';
import searchlg from '../../icons/Gerator/profile_settings/searchlg.svg';
import chevronright from '../../icons/Gerator/profile_settings/chevronright.svg';
import placeholder1 from '../../icons/Gerator/profile_settings/placeholder1.svg';
import { NoListingFound } from '../NoListing/NoListingFound';
import { useLazyFindGetBusinessQuery, useLazyFindGetMyBusinessQuery } from '../../api/BusinessAPI';
import { Helmet } from 'react-helmet-async';
import { NoBusinessFound } from '../NoBusiness/NoBusiness';
import arrowsquareupright from "../../icons/Gerator/dashboard/arrowsquareupright.svg";
import BusinessOwnerSvg from "../../icons/Gerator/dashboard/BusinessOwnerSvg.svg";
import TeamMemberSvg from "../../icons/Gerator/dashboard/TeamMemberSvg.svg"
import CoOwnerSvg from "../../icons/Gerator/dashboard/CoOwnerSvg.svg"
import dotsvertical from '../../icons/Gerator/dashboard/dotsvertical.svg';
import BusinessDropdown from '../BusinessDropdown/BusinessDropdown';
import { encryptData } from '../../helper/AESHelper';
import { NoAccessBusiness } from '../NoAccessBusiness/NoAccessBusiness';
import bookmarkcheck from "../../icons/Gerator/detailed_listing/bookmarkcheck.svg";
import { ErrorShowComponent } from '../ErrorShowComponent/ErrorShowComponent';
import { useAddRequestMemberMutation, useLazyGetRequestMemberStatusQuery } from '../../api/MemberAPI';

export const BusinessFormView = ({ currentUser, currentUserMeta }) => {


  const [isNewDropdownVisible, setNewDropdownVisible] = useState(false);
  const [isMemberRequested, setMemberRequested] = useState(null);
  const newdropdownRef = useRef(null);


  const toggleNewDropdown = () => {
    setNewDropdownVisible(!isNewDropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {

      const isLink = event.target.closest('a');

      if (isLink) {
        return; // Allow navigation to happen
      }

      if (newdropdownRef.current && !newdropdownRef.current.contains(event.target)) {
        setNewDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Get Business Full detail to show on page
  const [triggerGetBusiness, { data, isSuccess, error, isLoading, isError }] = useLazyFindGetMyBusinessQuery();
  // Get Status of member if it requested or not
  const [triggerGetMemberRequestStatus, { data: memberRequestStatusData, isSuccess: isMemberRequestStatusSuccess }] = useLazyGetRequestMemberStatusQuery();
  // Add request of member to db 
  const [addRequestedMember, { isSuccess: isRequestedMemberSuccess, isLoading: isRequestedMemberLoading }] = useAddRequestMemberMutation();
  // const [currentUser, setCurrentUser] = useState("");
  // const [formLoadData, setFormLoadData] = useState("");
  // const navigate = useNavigate();

  useEffect(() => {
    if (currentUserMeta?.isUserEmailDomainPrivate) {
      //limiting query run because when domain is private then this query will run
      triggerGetBusiness();
      triggerGetMemberRequestStatus();
    }
  }, [currentUser, currentUserMeta]);


  // useEffect(() => {
  //   if (isSuccess) {
  //   }
  // }, [isSuccess])

  useEffect(() => {
    if (isMemberRequestStatusSuccess) {
      console.log(memberRequestStatusData);
      setMemberRequested(memberRequestStatusData.status);
    }
  }, [isMemberRequestStatusSuccess])


  const handleAddRequestMember = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {

      const response = await addRequestedMember({ id: data.id }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        window.location.reload();
      }
    } catch (error) {
      return <ErrorShowComponent message1={"Internal server error"} />
    }
  };

  //Ensuring both user and meta is loaded then second if condition will work
  if (currentUser && currentUserMeta) {
    if (currentUserMeta?.isUserEmailDomainPrivate === false) {
      return <NoAccessBusiness path={currentUser.email} />
    }
  }


  if (isError) {
    if (!error.data.status) {
      //Add this when team member is added. this is useful when user has private email domain
      return <ErrorShowComponent message1={"Internal server error"} />
    }
  }


  if (isSuccess && (isMemberRequested !== null)) {
    // console.log(data);
    if (data.status) {
      return (
        <>
          <Helmet>
            <title>Settings - Business</title>
          </Helmet>
          <div className="business-display-form-content-wrapper">
            <div className="business-form-form-content-wrapper">
              <div className="business-form-form-content">
                <div className="business-form-toggle-header-parent">
                  <div className="business-form-toggle-header">
                    <div className="business-form-toggle-title">My Business</div>
                    <div className="business-form-supporting-text2">
                      Your success story starts right here
                    </div>
                  </div>
                  <div className="business-form-toggle-controls">
                    <div >
                      {
                        currentUserMeta.userType === 2
                          ? <img
                            className="business-display-badge-icon1"
                            alt=""
                            src={BusinessOwnerSvg}
                          />
                          : currentUserMeta.userType === 3
                            ? <img
                              className="business-display-badge-icon1"
                              alt=""
                              src={TeamMemberSvg}
                            />
                            : currentUserMeta.userType === 4
                              ? <img
                                className="business-display-badge-icon1"
                                alt=""
                                src={CoOwnerSvg}
                              />
                              : null // Default value or error handling
                      }
                    </div>



                    {/* <div className="business-form-rectangle-group">
                      <div className="business-form-frame-item"></div>
                      <div className="business-form-toggle-base">
                        <div className="business-form-button6"></div>
                      </div>
                      <div className="business-form-switch-label">Explorer mode</div>
                      <div className="business-form-supporting-text3">
                        Save my login details for next time.
                      </div>
                    </div> */}
                    {/* <button className="business-form-button7">
                      <img
                      className="business-form-placeholder-icon"
                      alt=""
                      src="/profile_settings/placeholder2.svg"
                      />
                      
                      <div className="business-form-button-labels">Business Owner</div>
                      <img
                        className="business-form-placeholder-icon"
                        alt=""
                        src="/profile_settings/placeholder2.svg"
                        />
                    </button> */}
                    <div className="team-display-toggle-supporting-text">
                      {currentUserMeta.userType === 1 && (
                        <>
                          {(currentUserMeta.userPlan === 1 || currentUserMeta.userPlan === 14) ? (
                            <button
                              className="business-form-button8"
                              onClick={() => window.location.href = '/dashboard/settings/plan'}>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder3.svg"
                              />
                              <div className="business-form-text49">Upgrade</div>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src="/profile_settings/placeholder3.svg"
                              />
                            </button>
                          ) :
                            (isMemberRequested ?

                              <div className="business-form-button7" >
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                                <div className="profile-setting-button-labels">Requested Access</div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                              </div>

                              :
                              <button className="business-form-button8" onClick={handleAddRequestMember}>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                                <div className="business-form-text49">Request Access</div>
                                <img
                                  className="business-form-placeholder-icon"
                                  alt=""
                                  src="/profile_settings/placeholder3.svg"
                                />
                              </button>
                            )
                          }
                          <div className="business-form-view-supporting-text3">
                            For team member access
                          </div>
                        </>
                      )}
                    </div>


                    <div >
                      <img
                        className="business-display-dots-icon1"
                        alt=""
                        src={dotsvertical}
                        onClick={toggleNewDropdown}
                        style={{ cursor: 'pointer' }}
                        ref={newdropdownRef}
                      />
                    </div>
                    {isNewDropdownVisible && (
                      <div className="business-display-dropdown-wrapper" >
                        <BusinessDropdown currentUserMeta={currentUserMeta} />
                      </div>
                    )}
                  </div>
                </div>






                <div className="business-form-divider-container">
                  <img
                    className="business-form-divider-icon3"
                    alt=""
                    src={divider2}
                  />
                </div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Business Name
                    </div>
                    <div className="business-form-supporting-text2">
                      Cannot be edited
                    </div>
                  </div>
                  <div className="business-form-input-field1 ">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input4 input-disabled">
                        <div className="business-form-content19 ">

                          <input
                            className="business-form-extra-input-info"
                            disabled value={data.businessName}
                            type="text"
                          />
                          <img
                            className="account-update-profile-home-line-icon"
                            alt=""
                            src={lock04}
                          />
                        </div>

                      </div>
                    </div>
                    <div className="business-form-contact-button">
                      <div className="business-form-message-button">
                        <div className="business-form-navigation">
                          <img
                            className="business-form-bookmark-check-icon"
                            alt=""
                            src={bookmarkcheck}
                          />
                        </div>
                        <div className="business-form-supporting-text10">
                          Verified by Gerätor
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="business-display-dashboard-arrow-square-up-right-wrapper1">
                    <Link to={`/business/${data.id}`}>

                      <img
                        className="business-display-dashboard-arrow-square-up-right-icon1"
                        alt=""
                        src={arrowsquareupright}
                      />
                    </Link>

                  </div>


                </div>
                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Website </div>
                    <div className="business-form-supporting-text2">
                      Cannot be edited
                    </div>
                  </div>
                  <div className="business-form-input-field1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input4 input-disabled">
                        <div className="business-form-content19 ">


                          <input
                            className="business-form-extra-input-info"
                            disabled value={data.businessWebsite}
                          />
                          <img
                            className="account-update-profile-home-line-icon"
                            alt=""
                            src={lock04}
                          />
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Business Genre </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">


                          <input className="business-form-extra-input-info" disabled value={data.tax_business_genre.name} />

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Ownership  </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">
                          <input className="business-form-extra-input-info" disabled value={data.tax_ownership.name} />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Size </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">


                          <input className="business-form-extra-input-info" disabled value={data.tax_business_size.name} />

                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Expertise Highlights

                    </div>
                  </div>
                  <div className="business-form-input-field1">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input4">
                        <div className="business-form-content19 ">
                          <textarea
                            className="add-service-step3-text82 business-form-extra-input-info-custom "
                            disabled value={data.businessExpertiseHighlights}
                          />

                          {/* <input
                                      className="business-form-extra-input-info"
                                      type="text"
                                      disabled value={data.businessExpertiseHighlights}
                                    /> */}
                        </div>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Expertise Description</div>
                  </div>
                  <div className="business-form-toolbar-and-text-input">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-textarea-input-field">
                        <div className="business-form-input16 ">
                          <textarea
                            className="add-service-step3-text82 business-form-extra-input-info-custom "
                            disabled value={data.businessExpertiseDescription}
                          />
                        </div>
                      </div>
                      <div className="business-form-supporting-text4">
                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-toggle-header-parent">
                  <div className="business-form-toggle-header">
                    <div className="business-form-toggle-title1"> Business Location</div>

                  </div>

                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Country </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input8">
                        <div className="business-form-content29 ">


                          <input className="business-form-extra-input-info" disabled value={data.tax_country.name} />

                        </div>

                      </div>
                    </div>
                  </div>
                </div>


                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Building </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input9 ">
                        <div className="business-form-content31 ">
                          <input className="business-form-extra-input-info" disabled value={data.businessBuilding} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Street </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">
                      <div className="business-form-input9 ">
                        <div className="business-form-content31 ">
                          <input className="business-form-extra-input-info" disabled value={data.businessStreet} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">State </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessState}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Town / City</div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessTownCity}
                        />
                      </div>
                    </div>

                  </div>
                </div>


                <div className="business-form-divider"></div>

                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">District </div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessDistrict}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <div className="business-form-divider"></div>
                <div className="business-form-content18">
                  <div className="business-form-section-label1">
                    <div className="business-form-title">Postcode / ZIP</div>
                  </div>
                  <div className="business-form-input-dropdown1">
                    <div className="business-form-textarea-input-field">

                      <div className="business-form-input10 ">
                        <input
                          className="business-form-extra-input-info"

                          type="text"
                          disabled value={data.businessPostcodeZip}
                        />
                      </div>
                    </div>

                  </div>
                </div>


                <div className="business-form-toggle-header-parent">
                  <div className="business-form-toggle-header">
                    <div className="business-form-toggle-title1"> Business Profile </div>

                  </div>

                </div>
                <div className="business-form-content44 business-form-content44-custom">
                  <div className="business-display-toggle-title1 custom-banner">Banner
                    <div className="business-display-rectangle-group custom-banner-width custom-padding">
                      <div className="business-display-rectangle-container custom-banner-width">
                        {/* <div className="business-display-frame-inner"></div> */}
                        <img
                          className="business-display-f4b711e503bc72fe396e5043e0c2-7-icon"
                          loading="lazy"
                          alt=""
                          src={data.bannerImageLink}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="business-form-file-upload">
                    {/* <div className="business-form-file-upload-base1">
                      <div className="business-form-content45">
                        <img
                          className="business-form-featured-icon"
                          alt=""
                          src={featured_icon}
                        />
  
                        <div className="business-form-text-and-supporting-text1">
                          <div className="business-form-action">
                            <div className="business-form-buttonsbutton1">
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
  
                              <div className="business-form-text54">Click to upload</div>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="business-form-text55">or drag and drop</div>
                          </div>
                          <div className="business-form-supporting-text5">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </div>
                        </div>
                      </div>
                    </div> */}



                    <div className="add-service-step-file-upload-item-base">
                      <div className="add-service-step-first-upload-item-content">
                        <div className="add-service-step-file-type-icon">
                          <img
                            className="add-service-step-page-icon"
                            loading="lazy"
                            alt=""
                            src={page}
                          />

                          <div className="add-service-step-file-type-wrap">
                            <b className="add-service-step-file-type">IMG</b>
                          </div>
                        </div>
                        <div className="add-service-step-first-upload-item-info">
                          <div className="add-service-step-first-upload-item-inner">
                            <div className="add-service-step-first-upload-item">
                              {data.banner_image.file_name}
                            </div>
                            <div className="add-service-step3-checkbox3">
                              <input type="checkbox" name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2" />
                            </div>
                          </div>
                          <div className="add-service-step3-supporting-text14">{(data.banner_image.file_size / 1000).toFixed(1) + " KB"}</div>
                        </div>
                      </div>
                      <div className="add-service-step3-progress-bar8">
                        <div className="add-service-step3-progress-bar-wrapper">
                          <div className="add-service-step3-progress-bar9">
                            <div className="add-service-step3-background3"></div>
                            <div className="add-service-step3-progress3"></div>
                          </div>
                        </div>
                        <div className="add-service-step3-percentage3">100%</div>
                      </div>
                    </div>
                  </div>


                </div>
                <div className="business-form-divider-container">
                  <img
                    className="business-form-divider-icon3"
                    alt=""
                    src={divider2}
                  />
                </div>
                <div className="business-form-content44 business-form-content44-custom">
                  {/* <div className="business-form-section-label1">
                              <div className="business-form-title">Logo <span className="business-form-span">*</span></div>
                              <div className="business-form-supporting-text4">
  
                              </div>
                            </div> */}
                  <div className="business-display-toggle-title1 custom-logo" >Logo

                    <div className="business-display-rectangle-group custom-logo-width custom-padding">
                      <div className="business-display-rectangle-container custom-logo-width">
                        {/* <div className="business-display-frame-inner"></div> */}
                        <img
                          className="business-display-f4b711e503bc72fe396e5043e0c2-7-icon"
                          loading="lazy"
                          alt=""
                          src={data.logoImageLink}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="business-form-file-upload">
                    {/* <div className="business-form-file-upload-base1">
                      <div className="business-form-content45">
                        <img
                          className="business-form-featured-icon"
                          alt=""
                          src={featured_icon}
                        />
  
                        <div className="business-form-text-and-supporting-text1">
                          <div className="business-form-action">
                            <div className="business-form-buttonsbutton1">
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
  
                              <div className="business-form-text54">Click to upload</div>
                              <img
                                className="business-form-placeholder-icon"
                                alt=""
                                src={placeholder1}
                              />
                            </div>
                            <div className="business-form-text55">or drag and drop</div>
                          </div>
                          <div className="business-form-supporting-text5">
                            SVG, PNG, JPG or GIF (max. 800x400px)
                          </div>
                        </div>
                      </div>
                    </div> */}



                    <div className="add-service-step-file-upload-item-base">
                      <div className="add-service-step-first-upload-item-content">
                        <div className="add-service-step-file-type-icon">
                          <img
                            className="add-service-step-page-icon"
                            loading="lazy"
                            alt=""
                            src={page}
                          />

                          <div className="add-service-step-file-type-wrap">
                            <b className="add-service-step-file-type">IMG</b>
                          </div>
                        </div>
                        <div className="add-service-step-first-upload-item-info">
                          <div className="add-service-step-first-upload-item-inner">
                            <div className="add-service-step-first-upload-item">
                              {data.logo_image.file_name}
                            </div>
                            <div className="add-service-step3-checkbox3">
                              <input type="checkbox" name="imageCheckbox" checked className="checkbox-instance checkbox-base size-30-sm state-8-default checked-true type-checkbox checkbox-2" />
                            </div>
                          </div>
                          <div className="add-service-step3-supporting-text14">{(data.logo_image.file_size / 1000).toFixed(1) + " KB"}</div>
                        </div>
                      </div>
                      <div className="add-service-step3-progress-bar8">
                        <div className="add-service-step3-progress-bar-wrapper">
                          <div className="add-service-step3-progress-bar9">
                            <div className="add-service-step3-background3"></div>
                            <div className="add-service-step3-progress3"></div>
                          </div>
                        </div>
                        <div className="add-service-step3-percentage3">100%</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="business-form-divider"></div> */}






              </div>
            </div>
          </div>
        </>

      )
    }
    else {
      return <NoBusinessFound path={'/dashboard/settings/business/add-business'} pathName={"Business"} />
    }
  }




}

