import React from 'react';
import { Link } from 'react-router-dom';
import "../FunnelJobComponent/FunnelJobComponent.css";
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import playstation from '../../../icons/Gerator/sales_iq/playstation.jpeg';
import radar_1_1_2x from '../../../icons/Gerator/sales_iq/radar-1-1@2x.png';
import deal_2_1_2x from '../../../icons/Gerator/sales_iq/deal-2-1@2x.png';
import magnifier_1_2x from '../../../icons/Gerator/sales_iq/magnifier-1@2x.png';
import arrowup from "../../../icons/Gerator/sales_iq/arrowup.svg";
import _chart_mini2 from "../../../icons/Gerator/sales_iq/-chart-mini2@2x.png";
import markerpin02 from '../../../icons/Gerator/dashboard/markerpin02.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';

import { PostStatusLIstingComponent } from '../../PostStatusLIstingComponent/PostStatusLIstingComponent';
import { TransactionTypeComponent } from '../../TransactionTypeComponent/TransactionTypeComponent';


export const FunnelOfferedJobCard = () => {
  return (
   <>
     {/* SalesIQ Card  */}
     <div className="sales-iq-component-listing-container">

           {/* <div className="sales-iq-detail-device-component-sales-iq-content-items" key={index}> */}
         
              <div className="sales-iq-detail-device-component-sales-iq-content-items" >
          <div className="sales-iq-detail-device-component-sales-iq-divider-container1">
            <div className="sales-iq-detail-device-component-sales-iq-rectangle-parent5">
              <img
                className="sales-iq-detail-device-component-sales-iq-frame-child7"
                alt=""
                // src={device.featureImage}
                src={playstation}
                
              />

              <div className="sales-iq-detail-device-component-sales-iq-frame-parent1">
                <div className="sales-iq-detail-device-component-sales-iq-action-fields">
                  <div className="sales-iq-detail-device-component-sales-iq-input-elements">
                    {/* <Link to={`/device/${device.id}/${device.title}`} className="sales-iq-detail-device-component-sales-iq-text78">
                      {device.title}
                    </Link> */}
                    <div className="sales-iq-detail-device-component-sales-iq-text78">
                        PRE-OWNED / USED / PARTIALLY REFURBISHED 1995 12TH MAN SBJ123
                    </div>
                    <div className="sales-iq-detail-device-component-sales-iq-frame-parent21">
                      <div className="sales-iq-detail-device-component-sales-iq-badge-item-parent">
                        <div className="sales-iq-detail-device-component-sales-iq-badge-parent">

                          {/* <PostStatusLIstingComponent postStatus={device.tax_post_status.name} postSlug={device.tax_post_status.slug} postId={device.id} /> */}
                          <PostStatusLIstingComponent postStatus={"Pending"} postSlug={"pending"} postId={1} />

                        </div>
                        <div className="sales-iq-detail-device-component-sales-iq-frame-parent4">
                          <div className="sales-iq-detail-device-component-sales-iq-end-label">
                            <div className="sales-iq-detail-device-component-sales-iq-search-icon-input">4.9/5</div>
                          </div>
                          <div className="sales-iq-detail-device-component-sales-iq-type-support">
                            <div className="sales-iq-detail-device-component-sales-iq-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>
                     
                      </div>
                      <div className="sales-iq-detail-device-component-sales-iq-frame-parent24">
                        <div className="sales-iq-detail-device-component-sales-iq-location-pin-parent">
                          <div className="sales-iq-detail-device-component-sales-iq-search-input">
                            <img
                              className="sales-iq-detail-device-component-sales-iq-marker-pin-02-icon2"
                              alt=""
                              src={markerpin02}
                            />
                          </div>
                          {/* <div className="sales-iq-detail-device-component-sales-iq-location3">{device.device_address.addressTownCity}, {device.device_address.tax_country.name}</div> */}
                          <div className="sales-iq-detail-device-component-sales-iq-location3">Oklahoma City , United States of America</div>
                        </div>
                       
                      </div>
                      <div className="sales-iq-detail-device-component-sales-iq-frame-parent26">
                        <div className="sales-iq-detail-device-component-sales-iq-frame-wrapper19">
                          <div className="sales-iq-detail-device-component-sales-iq-legend-series">
                            <div className="sales-iq-detail-device-component-sales-iq-search-input">
                              <img
                                className="sales-iq-detail-device-component-sales-iq-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            {/* <div className="sales-iq-detail-device-component-sales-iq-type-of-work2">{getCustomizedDate(device.createdAt)}</div> */}
                            <div className="sales-iq-detail-device-component-sales-iq-type-of-work2">November 13,2024</div>
                          </div>
                        </div>
                       
                      </div>
                    
                    </div>
                  </div>

                  <div className= "sales-iq-detail-device-component-sales-iq-small-card">
                          
                      <div className="sales-iq-detail-device-component-sales-iq-badge33">
                              <div className="sales-iq-detail-device-component-sales-iq-third-badge-number">
                                {/* {device.tax_device_category.name} */}
                                1/25-DIHYDROXYDEVICE
                              </div>
                      </div>

                      <div className="sales-iq-detail-device-component-sales-iq-badge36">
                              <div className="sales-iq-detail-device-component-sales-iq-third-badge-number">
                                11 Health
                              </div>
                      </div>

                      <div className="sales-iq-detail-device-component-sales-iq-badge37">
                              {/* <div className="sales-iq-detail-device-component-sales-iq-third-badge-number">{device.tax_oem.name}</div> */}
                              <div className="sales-iq-detail-device-component-sales-iq-third-badge-number">
                           12TH MAN
                           </div>
                      </div>

                      <div className="sales-iq-detail-device-component-sales-iq-badge38">
                          <div className="sales-iq-detail-device-component-sales-iq-third-badge-number">
                            {/* {device.tax_clinical_application.name} */}
                            Pathology
                          </div>
                      </div>

                      <div className="dashboard-device-component-dashboard-badge39">
                        <div className="dashboard-device-component-dashboard-third-badge-number">
                          Accounting
                        </div>
                      </div>

                  </div>

                </div>


                <div className="sales-iq-detail-device-component-sales-iq-filter-elements">
                  {/* <div className="sales-iq-detail-device-component-sales-iq-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}> */}
                  <div className="sales-iq-detail-device-component-sales-iq-dropdown10" >

                    <img
                      className="sales-iq-detail-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                    />
                    {/* {activeThreeDotId === index && <ThreeDotsListingComponent url={`device/${device.id}`} />} */}
                  </div>
                  <div className="sales-iq-detail-device-component-sales-iq-filter-elements-inner">
                    <div className="sales-iq-detail-device-component-sales-iq-price-parent">
                      {/* <TransactionTypeComponent transactionType={device.tax_transaction_type.name} slug={device.tax_transaction_type.slug} /> */}
                      <TransactionTypeComponent transactionType={"Sale"} slug={"sale"} />

                      {/* <div className="sales-iq-detail-device-component-sales-iq-price2">${formatNumber(device.price)}</div> */}
                      <div className="sales-iq-detail-device-component-sales-iq-price2">$258</div>

                      <div className="sales-iq-detail-device-component-sales-iq-dropdown-elements">
                        <div className="sales-iq-detail-device-component-sales-iq-dropdown-options-parent">
                          {/* <div className="sales-iq-detail-device-component-sales-iq-text83">{device.tax_unit_measure_device_spare.name}</div> */}
                          <div className="sales-iq-detail-device-component-sales-iq-text83">USD - Based on terms of exhange</div>

                          <div className="sales-iq-detail-device-component-sales-iq-frame-wrapper3">
                            <div className="sales-iq-detail-device-component-sales-iq-radar-1-1-container">
                              <img
                                className="sales-iq-detail-device-component-sales-iq-magnifier-1-icon"
                                alt=""
                                src={radar_1_1_2x}
                              />

                              <img
                                className="sales-iq-detail-device-component-sales-iq-magnifier-1-icon"
                                alt=""
                                src={deal_2_1_2x}
                              />

                              <img
                                className="sales-iq-detail-device-component-sales-iq-magnifier-1-icon"
                                alt=""
                                src={magnifier_1_2x}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              
              </div>
            </div>

            <img className="sales-iq-detail-device-component-sales-iq-divider-icon8" alt="" src={divider2}/>

              <div className="sales-iq-detail-device-component-sales-iq-metric-item-parent">

              <div className="sales-iq-detail-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-detail-device-component-sales-iq-heading9">Active engagement</div>
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-chart">
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-detail-device-component-sales-iq-heading">2,137</div>
                      <div className="sales-iq-detail-device-component-sales-iq-change-and-text3">
                      <div className="sales-iq-detail-device-component-sales-iq-change2">
                          <img
                          className="sales-iq-detail-device-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                          />

                          <div className="sales-iq-detail-device-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-detail-device-component-sales-iq-text139">vs last month</div>
                      </div>
                  </div>
                  <img
                      className="sales-iq-detail-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}
                      
                  />
                  </div>
                  <div className="sales-iq-detail-device-component-sales-iq-dropdown19">
                  <img
                      className="sales-iq-detail-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                  />
                  </div>
              </div>

              <div className="sales-iq-detail-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-detail-device-component-sales-iq-heading9">Views</div>
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-chart">
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-detail-device-component-sales-iq-heading">2,137</div>
                      <div className="sales-iq-detail-device-component-sales-iq-change-and-text3">
                      <div className="sales-iq-detail-device-component-sales-iq-change2">
                          <img
                          className="sales-iq-detail-device-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                          />

                          <div className="sales-iq-detail-device-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-detail-device-component-sales-iq-text139">vs last month</div>
                      </div>
                  </div>
                  <img
                      className="sales-iq-detail-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}
                      
                  />
                  </div>
                  <div className="sales-iq-detail-device-component-sales-iq-dropdown19">
                  <img
                      className="sales-iq-detail-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                  />
                  </div>
              </div>
              <div className="sales-iq-detail-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-detail-device-component-sales-iq-heading9">Added to Radar</div>
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-chart">
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-detail-device-component-sales-iq-heading">769</div>
                      <div className="sales-iq-detail-device-component-sales-iq-change-and-text3">
                      <div className="sales-iq-detail-device-component-sales-iq-change2">
                          <img
                          className="sales-iq-detail-device-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                          />

                          <div className="sales-iq-detail-device-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-detail-device-component-sales-iq-text139">vs last month</div>
                      </div>
                  </div>
                  <img
                      className="sales-iq-detail-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}
                  />
                  </div>
                  <div className="sales-iq-detail-device-component-sales-iq-dropdown19">
                  <img
                      className="sales-iq-detail-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                  />
                  </div>
              </div>
              <div className="sales-iq-detail-device-component-sales-iq-metric-item3">
                  <div className="sales-iq-detail-device-component-sales-iq-heading9">Searches</div>
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-chart">
                  <div className="sales-iq-detail-device-component-sales-iq-number-and-badge3">
                      <div className="sales-iq-detail-device-component-sales-iq-heading">1,859</div>
                      <div className="sales-iq-detail-device-component-sales-iq-change-and-text3">
                      <div className="sales-iq-detail-device-component-sales-iq-change2">
                          <img
                          className="sales-iq-detail-device-component-sales-iq-upload-04-icon"
                          alt=""
                          src={arrowup}
                          />

                          <div className="sales-iq-detail-device-component-sales-iq-change7">100%</div>
                      </div>
                      <div className="sales-iq-detail-device-component-sales-iq-text139">vs last month</div>
                      </div>
                  </div>
                  <img
                      className="sales-iq-detail-device-component-sales-iq-chart-mini-icon3"
                      alt=""
                      src={_chart_mini2}
                  />
                  </div>
                  <div className="sales-iq-detail-device-component-sales-iq-dropdown19">
                  <img
                      className="sales-iq-detail-device-component-sales-iq-dots-vertical-icon"
                      alt=""
                      src={dotsvertical}
                  />
                  </div>
              </div>
              </div>

            <img className="sales-iq-detail-device-component-sales-iq-divider-icon8" alt="" src={divider2} />

            <div className="sales-iq-detail-device-component-sales-iq-frame-parent71">
              <div className="sales-iq-detail-device-component-sales-iq-buttonsbutton-parent1">
                <div className="sales-iq-legend-series">
                  <img
                    className="sales-iq-location-column-child"
                    alt=""
                    src={group_58_2x}
                  />

                  <div className="sales-iq-location-column-inner">
                    <div className="sales-iq-date-picker-dropdown">
                      {/* <div className="sales-iq-name">{device.device_business.business_user.displayName}</div>
                      <div className="sales-iq-role">{device.device_business.businessName}</div> */}

                        <div className="sales-iq-name">Prithvi Raj </div>
                        <div className="sales-iq-role">Medico</div>
                    </div>
                  </div>
                </div>
                <div className='sales-iq-detail-device-component-main-buttons-group'>
                  <div className="sales-iq-detail-device-component-sales-iq-buttonsbutton13">
                    <img
                      className="sales-iq-detail-device-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="sales-iq-detail-device-component-sales-iq-search-input">
                      <div className="sales-iq-detail-device-component-sales-iq-text67">View</div>
                    </div>
                  </div>
                  {/* <div className="sales-iq-detail-device-component-sales-iq-buttonsbutton25">
                    <div className="sales-iq-detail-device-component-sales-iq-icon-wrapper">
                      <img className="sales-iq-detail-device-component-sales-iq-icon1" alt="" src={icon1} />
                    </div>
                    <div className="sales-iq-detail-device-component-sales-iq-search-input">
                      <div className="sales-iq-detail-device-component-sales-iq-text68">Sales IQ</div>
                    </div>
                    <img
                      className="sales-iq-detail-device-component-sales-iq-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div> */}

                  <Link to={"/sales-iq-detail-device"}>
                      <div className="sales-iq-detail-device-component-sales-iq-buttonsbutton26">
                        <div className="sales-iq-detail-device-component-sales-iq-icon-wrapper">
                          <img className="sales-iq-detail-device-component-sales-iq-icon1" alt="" src={icon1} />
                        </div>
                        <div className="sales-iq-detail-device-component-sales-iq-search-input">
                          <div className="sales-iq-detail-device-component-sales-iq-text68">Sales IQ</div>
                        </div>
                        <img
                          className="sales-iq-detail-device-component-sales-iq-arrow-narrow-left-icon"
                          alt=""
                          src={lockunlocked04}
                        />
                      </div>
                  </Link>


                  <div className="sales-iq-detail-device-component-sales-iq-buttonsbutton27">
                    <img
                      className="sales-iq-detail-device-component-sales-iq-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="sales-iq-detail-device-component-sales-iq-text145">Messages</div>
                    <div className="sales-iq-detail-device-component-sales-iq-ellipse-parent">
                      <div className="sales-iq-detail-device-component-sales-iq-ellipse-div"></div>
                      <div className="sales-iq-detail-device-component-sales-iq-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="sales-iq-detail-device-component-sales-iq-arrow-square-up-right-wrapper1">
                <img
                  className="sales-iq-detail-device-component-sales-iq-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div>
        </div>
      
   </>
  )
}

