import React, { useState } from 'react';
import './AdminDashboardOfferedandWantedTab.css'; // Import your CSS file

import { AdminDashboardOfferedCards } from '../AdminDashboardOfferedCards/AdminDashboardOfferedCards';

import { AdminDashboardWantedCards } from '../AdminDashboardWantedCards/AdminDashboardWantedCards';

export const AdminDashboardOfferedandWantedTab = () => {
    const [activeButton, setActiveButton] = useState('Offered');

    const handleButtonClick = (switchButton) => {
        setActiveButton(switchButton);
    };

    const renderComponent = () => {
        switch (activeButton) {
            case 'Offered':
                return <AdminDashboardOfferedCards />;
            case 'Wanted':
                return <AdminDashboardWantedCards />;
            default:
                return null;
        }
    };


    return (
        <div className='admin-dashboard-tab-main-container'>
            <div className='admin-dashboard-tab-tab'>
                <div className="admin-dashboard-tab-button-group custom-cursor">
                    <div
                        className={`admin-dashboard-tab-button-group-base1 ${activeButton === 'Offered' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Offered')}
                    >
                        <div className="admin-dashboard-tab-text31">Offered</div>
                    </div>
                    <div
                        className={`admin-dashboard-tab-button-group-base1 ${activeButton === 'Wanted' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Wanted')}
                    >
                        <div className="admin-dashboard-tab-text32">Wanted</div>
                    </div>

                </div>

            </div>

            <div>
                {renderComponent()}
            </div>

        </div>
    );
};


export default AdminDashboardOfferedandWantedTab;
