
export function objectToMemberData(obj, domain) {

  // Destructure to remove file fields from obj
  const { email, ...values } = obj;

  values.email = email + '@' + domain;

  console.log(values);


  // // Encrypt the object without the document
  // const encryptedValue = encryptData(values); // Custom encryptData function

  return values;
}