import React, { useState } from 'react';
import './AdminDashboardWantedFilterButtonGroup.css'; // Import your CSS file

const AdminDashboardWantedFilterButtonGroup = ({ handleFilterClick, activePostStatus }) => {
    const getTextClass = (filter) => {
        switch (filter) {
            case 'Published': return '31';
            case 'Archived': return '29';
            case 'Expired': return '39';
            default: return '';
        }
    };

    return (
        <div className="admin-dashboard-button-group custom-cursor">
            {['Published',  'Archived', 'Expired'].map(filter => (
                <div
                    key={filter}
                    className={`admin-dashboard-button-group-base1 ${activePostStatus === filter ? 'active' : ''}`}
                    onClick={() => handleFilterClick(filter)}
                >
                    <div className={`admin-dashboard-text${getTextClass(filter)}`}>
                        {filter}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AdminDashboardWantedFilterButtonGroup;
