import React from 'react';
import "../NoAccessTeam/NoAccessTeam.css";
import bifocallens from '../../icons/Gerator/no_listing/bifocallens.png';
import placeholder from '../../icons/Gerator/no_listing/placeholder.svg';
import placeholder1 from '../../icons/Gerator/no_listing/placeholder1.svg';
import plus from '../../icons/Gerator/no_listing/plus.svg';
import { Link } from "react-router-dom";
import NoAccess from "../../icons/Gerator/business_page/NoAccess.png";

export const NoAccessTeamNormal = ({ path, pathName }) => {
  return (
    <div className="no-access-team-no-access-business">
      <section className="no-access-team-empty-state">
        <div className="no-access-team-empty-state1">
          <div className="no-access-team-content">
            <img
              className="no-access-team-bifocal-optometry-measurement-icon"
              loading="lazy"
              alt=""
              src={NoAccess}
            />

            <div className="no-access-team-text-and-supporting-text">
              <div className="no-access-team-title">Take a pause to fix these issues</div>
              <div className="no-access-team-supporting-text">
                This feature is available only for Business Owner and Co-Owner.
              </div>
            </div>
          </div>
          <div className="no-access-team-actions">

            <button className="no-access-team-buttonsbutton">
              <img
                className="no-access-team-placeholder-icon"
                alt=""
                src={placeholder}
              />

              <div className="no-access-team-text-padding">
                <div className="no-access-team-text">Upgrade</div>
              </div>
              <img
                className="no-access-team-placeholder-icon"
                alt=""
                src={placeholder}
              />
            </button>


            <button className="no-access-team-buttonsbutton1"  >
              <div className='no-access-business-button-link'>
                {/* <img className="no-access-team-plus-icon" alt="" src={plus} /> */}

                <div className="no-access-team-text-padding">
                  <div className="no-access-team-text1">Request Co-ownership</div>
                </div>
                <img
                  className="no-access-team-placeholder-icon"
                  alt=""
                  src={placeholder1}
                />
              </div>
            </button>
          </div>


          <div className="no-access-team-supporting-text " >
            Check if you are connected to an approved business as Owner or Co-owner. Co-ownership is available only for Xenon, and Helium plans.
          </div>
        </div>
      </section>
    </div>
  )
}
