import { element } from "prop-types";
import { Dashboard } from "../screens/Dashboard/Dashboard";
import { SignIn } from "../screens/Sign_In/SignIn";
import { SignUp } from "../screens/Sign_Up/SignUp";
import { UserProfile } from "../screens/UserProfile/UserProfile";
import { Radar } from "../screens/Radar/Radar";
import { Beacon } from "../screens/Beacon/Beacon";
import { Contact } from "../screens/Contact/Contact";
import { SalesIQ } from "../screens/SalesIQ/SalesIQ";
import { Funnel } from "../screens/Funnel/Funnel";
import { DemandCenter } from "../screens/DemandCenter/DemandCenter";
import { AdminDashboard } from "../screens/AdminDashboard/AdminDashboard";



export const authRoutes = [
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/sign-up",
    element: <SignUp />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/radar",
    element: <Radar />
  },
  {
    path: "/beacon",
    element: <Beacon />
  },

  {
    path: "/contact",
    element: <Contact />
  },
  {
    path: "/edit-profile",
    element: <UserProfile />,
  },
  // {
  //   path: "/sales-iq",
  //   element: <SalesIQ />
  // },
  {
    path: "/funnel",
    element: <Funnel />,
  },
  {
    path: "/demand-center",
    element: <DemandCenter />,
  },
  {
    path: "/admin-dashboard",
    element: <AdminDashboard />,
  },

];
