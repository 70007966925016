import { encryptData } from "./AESHelper";

export function documentObjectToBusinessFormData(obj, id) {

  const { businessEntity, businessProofOfIdentity, businessProofOfAddress } = obj;


  const formData = new FormData();

  if (businessEntity && businessEntity.type === 'application/pdf') {
    formData.append("documentFileObject", businessEntity);
  }

  if (businessProofOfIdentity && businessProofOfIdentity.type === 'application/pdf') {
    formData.append("documentFileObject", businessProofOfIdentity);
  }

  if (businessProofOfAddress && businessProofOfAddress.type === 'application/pdf') {
    formData.append("documentFileObject", businessProofOfAddress);
  }

  const encryptedValue = encryptData({ id }); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  return formData;
}

export function objectToBusinessFormData(obj, email) {

  const formData = new FormData();
  const { logoImage, bannerImage, imageCheckbox, ...rest } = obj;
  const business = rest;
  business.businessWebsite = email;
  // Destructure to remove file fields from obj
  console.log("Business after remove image");

  console.log(business.businessWebsite);
  // Encrypt the object without the document
  const encryptedValue = encryptData(business); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  if (logoImage instanceof File) {
    formData.append("logoImageObject", logoImage);
  }

  if (bannerImage instanceof File) {
    formData.append("bannerImageObject", bannerImage);
  }

  return formData;
}


export function objectToBusinessEditFormData(obj, logoImageObject, bannerImageObject) {
  const formData = new FormData();

  // Destructure to remove file fields from obj
  const { logo_image, banner_image, logoImage, bannerImage, imageCheckbox, businessName, businessWebsite, ...values } = obj;




  // Append logoImage if it's a valid File
  if (logoImageObject instanceof File) {
    formData.append("logoImageObject", logoImageObject);
  }

  if (bannerImageObject instanceof File) {
    formData.append("bannerImageObject", bannerImageObject);
  }


  console.log(values);
  const encryptedValue = encryptData(values); // Custom encryptData function

  // Append the encrypted text data
  formData.append("encryptedData", encryptedValue);

  return formData;
}


export function getCustomizedDate(sqlDate) {
  // const date = ; 
  const formattedDate = new Date(sqlDate).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
}

export function getAllTaxonomyData() {


  return allTaxonomy;
}


export function isValidURL(urlString) {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
}

export function isAllURLsValid(urlArray) {
  for (const url of urlArray) {
    if (!isValidURL(url)) {
      return false; // If even one URL is invalid, return false
    }
  }
  return true; // All URLs were valid
}