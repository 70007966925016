import React, { useEffect, useRef, useState } from 'react';
import "./AdminDashboard.css";
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Footer } from '../../components/Footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { firestore, doc, getDoc, auth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../screens/Login/FirebaseClient";
import divider3 from '../../icons/Gerator/dashboard/divider3.svg';
import divider1 from '../../icons/Gerator/dashboard/divider1.svg';
import plus from '../../icons/Gerator/dashboard/plus.svg';
import homeline1 from '../../icons/Gerator/dashboard/homeline1.svg';
import chevronright from '../../icons/Gerator/dashboard/chevronright.svg';
import chart_data2x from '../../icons/Gerator/dashboard/-chart-data@2x.png';
import arrowup from '../../icons/Gerator/dashboard/arrowup.svg';
import arrowdown from '../../icons/Gerator/dashboard/arrowdown.svg';
import chart_mini2x from '../../icons/Gerator/dashboard/-chart-mini@2x.png';
import dotsvertical from '../../icons/Gerator/dashboard/dotsvertical.svg';
import chart_mini1_2x from '../../icons/Gerator/dashboard/-chart-mini1@2x.png';
import _1_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { DashboardDeviceComponent } from '../../components/Dashboard/DashboardDeviceComponent/DashboardDeviceComponent';
import { DashboardSpareComponent } from '../../components/Dashboard/DashboardSpareComponent/DashboardSpareComponent';
import { DashboardServiceComponent } from '../../components/Dashboard/DashboardServiceComponent/DashboardServiceComponent';
import { DashboardSoftwareComponent } from '../../components/Dashboard/DashboardSoftwareComponent/DashboardSoftwareComponent';
import { DashboardJobComponent } from '../../components/Dashboard/DashboardJobComponent/DashboardJobComponent';
import { Helmet } from 'react-helmet-async';
import PlanExpiryStatus from '../../components/PlanExpiryStatusComponent/PlanExpiryStatus';
import { useLazyGetCurrentPlanOfUserQuery } from '../../api/PlanAPI';
import { useLazyGetProfileImageLinkQuery } from '../../api/Login&RegisterAPI';
import { AdminDashboardDeviceComponent } from '../../components/AdminDashboard/AdminDashboardDeviceComponent/AdminDashboardDeviceComponent';
import { AdminDashboardSpareComponent } from '../../components/AdminDashboard/AdminDashboardSpareComponent/AdminDashboardSpareComponent';
import { AdminDashboardJobComponent } from '../../components/AdminDashboard/AdminDashboardJobComponent/AdminDashboardJobComponent';
import { AdminDashboardServiceComponent } from '../../components/AdminDashboard/AdminDashboardServiceComponent/AdminDashboardServiceComponent';
import { AdminDashboardSoftwareComponent } from '../../components/AdminDashboard/AdminDashboardSoftwareComponent/AdminDashboardSoftwareComponent';
import { AdminDashboardUserComponent } from '../../components/AdminDashboardUserComponent/AdminDashboardUserComponent';
import { AdminDashboardBusinessComponent } from '../../components/AdminDashboardBusinessComponent/AdminDashboardBusinessComponent';
import { AdminDashboardOfferedCards } from '../../components/AdminDashboardOfferedCards/AdminDashboardOfferedCards';
import AdminDashboardOfferedandWantedTab from '../../components/AdminDashboardOfferedandWantedTab/AdminDashboardOfferedandWantedTab';

// const auth = getAuth(app);


export const AdminDashboard = () => {

 

 


  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [activeTab, setActiveTab] = useState('device');
  const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  // const renderContent = () => {
  //   switch (activeTab) {
  //     case 'device':
  //       return <AdminDashboardDeviceComponent />;
  //     case 'spare':
  //       return <AdminDashboardSpareComponent />;
  //     case 'service':
  //       return <AdminDashboardServiceComponent />;
  //     case 'software':
  //       return <AdminDashboardSoftwareComponent />;
  //     case 'job':
  //       return <AdminDashboardJobComponent />;
     
  //     default:
  //       return null;
  //   }
  // };


  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);

  // useEffect(() => {
  //   navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
  // }, [isCurrentPlanError])



  useEffect(() => {
    if (isCurrentPlanSuccess && currentPlanData?.data?.order_plan?.id) {
      setCurrentPlanName(currentPlanData.data.order_plan.planName);
      setCurrentPlanExpiry(currentPlanData.data.orderExpiry);
    } else {
      console.log("No current plan data or ID not available.");
    }
  }, [isCurrentPlanSuccess, currentPlanData]);


  if (currentUser && isCurrentPlanSuccess && profileImageLink) {
    return (
      <div className="admin-dashboard-dashboard-frame-layout">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <div className="admin-dashboard-dashboard-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />

        <div className="admin-dashboard-all">
          <img className="admin-dashboard-divider-icon" alt="" src={divider3} />

          <div className="admin-dashboard-all-content">

            <Sidebar>
              <SideBarSubNormal userData={currentUser} />
            </Sidebar>

            <div className="admin-dashboard-frame-parent">
              <div className="admin-dashboard-main-wrapper">
                <div className="admin-dashboard-main dashboard-main-custom">
                  <div className="admin-dashboard-header-section">
                    <div className="admin-dashboard-container">
                      <div className="admin-dashboard-page-header">
                        <div className="admin-dashboard-breadcrumbs">
                          <div className="admin-dashboard-tabs">
                            <div className="admin-dashboard-breadcrumb-button-base">
                              <img
                                className="admin-dashboard-search-lg-icon"
                                alt=""
                                src={homeline1}
                              />
                            </div>
                            <img
                              className="admin-dashboard-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="admin-dashboard-breadcrumb-button-base1">
                              <div className="admin-dashboard-location">Dashboard</div>
                            </div>
                            <img
                              className="admin-dashboard-chevron-right-icon"
                              alt=""
                              src={chevronright}
                            />

                            <div className="admin-dashboard-breadcrumb-button-base2">
                              <div className="admin-dashboard-text20">...</div>
                            </div>
                            <img
                              className="admin-dashboard-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="admin-dashboard-breadcrumb-button-base3">
                              <div className="admin-dashboard-text21">Another link</div>
                            </div>
                            <img
                              className="admin-dashboard-chevron-right-icon2"
                              alt=""
                              src={chevronright}
                            />

                            <div className="admin-dashboard-breadcrumb-button-base4">
                              <div className="admin-dashboard-text22">Overview</div>
                            </div>
                          </div>
                        </div>
                        <div className="admin-dashboard-content13">
                          <div className="admin-dashboard-text-and-supporting-text">
                            <div className="admin-dashboard-heading">Welcome to Admin Dashboard, {currentUser.displayName}</div>

                            <div className="admin-dashboard-supporting-text1">
                              {/* You are on Krypton Plan, expiring in 23 days. */}
                              <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} />
                            </div>
                          </div>
                        </div>
                        <img
                          className="admin-dashboard-divider-icon1"
                          alt=""
                          src={divider1}
                        />


                      </div>
                      {/* <div className="admin-dashboard-tabs-and-filters">
                        <div className="admin-dashboard-button-group">
                          <div className="admin-dashboard-button-group-base">
                            <div className="admin-dashboard-text31">12 months</div>
                          </div>
                          <div className="admin-dashboard-button-group-base1">
                            <div className="admin-dashboard-text32">30 days</div>
                          </div>
                          <div className="admin-dashboard-button-group-base2">
                            <div className="admin-dashboard-text29">7 days</div>
                          </div>
                          <div className="admin-dashboard-button-group-base3">
                            <div className="admin-dashboard-text34">Text</div>
                          </div>
                          <div className="admin-dashboard-button-group-base4">
                            <div className="admin-dashboard-text34">Text</div>
                          </div>
                          <div className="admin-dashboard-button-group-base5">
                            <div className="admin-dashboard-text34">Text</div>
                          </div>
                          <div className="admin-dashboard-button-group-base6">
                            <div className="admin-dashboard-text34">Text</div>
                          </div>
                          <div className="admin-dashboard-button-group-base7">
                            <div className="admin-dashboard-text34">Text</div>
                          </div>
                          <div className="admin-dashboard-button-group-base8">
                            <div className="admin-dashboard-text39">24 hours</div>
                          </div>
                        </div>
                        <div className="admin-dashboard-actions2">
                          <div className="admin-dashboard-date-picker-dropdown">
                            <div className="admin-dashboard-buttonsbutton1">
                              <img
                                className="admin-dashboard-upload-04-icon"
                                alt=""
                                src={calendar}
                              />

                              <div className="admin-dashboard-text-padding6">
                                <div className="admin-dashboard-filter-date">Select dates</div>
                              </div>
                              <img
                                className="admin-dashboard-placeholder-icon"
                                alt=""
                                src={placeholder2}
                              />
                            </div>
                          </div>
                          <div className="admin-dashboard-buttonsbutton2">
                            <img
                              className="admin-dashboard-upload-04-icon"
                              alt=""
                              src={filterlines}
                            />

                            <div className="admin-dashboard-text-padding6">
                              <div className="admin-dashboard-filter-options">Filters</div>
                            </div>
                            <img
                              className="admin-dashboard-placeholder-icon"
                              alt=""
                              src={placeholder2}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="admin-dashboard-line-and-bar-chart-wrapper">
                    <div className="admin-dashboard-line-and-bar-chart">
                      <div className="admin-dashboard-line-and-bar-chart">
                        <div className="admin-dashboard-content14">
                          <div className="admin-dashboard-legend">
                            <div className="admin-dashboard-legend-series">
                              <div className="admin-dashboard-legend-color">
                                <div className="admin-dashboard-color"></div>
                              </div>
                              <div className="admin-dashboard-series-1">2022</div>
                            </div>
                            <div className="admin-dashboard-legend-series">
                              <div className="admin-dashboard-legend-color">
                                <div className="admin-dashboard-color1"></div>
                              </div>
                              <div className="admin-dashboard-series-2">2021</div>
                            </div>
                            <div className="admin-dashboard-legend-series">
                              <div className="admin-dashboard-legend-color">
                                <div className="admin-dashboard-color2"></div>
                              </div>
                              <div className="admin-dashboard-series-1">2020</div>
                            </div>
                            <div className="admin-dashboard-legend-series3">
                              <div className="admin-dashboard-color3"></div>
                              <div className="admin-dashboard-series-4">Series 4</div>
                            </div>
                            <div className="admin-dashboard-legend-series4">
                              <div className="admin-dashboard-color4"></div>
                              <div className="admin-dashboard-series-5">Series 5</div>
                            </div>
                            <div className="admin-dashboard-legend-series3">
                              <div className="admin-dashboard-color5"></div>
                              <div className="admin-dashboard-series-4">Series 6</div>
                            </div>
                          </div>
                          <div className="admin-dashboard-y-axis">
                            <div className="admin-dashboard-y-axis-line">
                              <div className="admin-dashboard-divider"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line1">
                              <div className="admin-dashboard-divider"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line1">
                              <div className="admin-dashboard-divider"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line1">
                              <div className="admin-dashboard-divider"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line1">
                              <div className="admin-dashboard-divider"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line1">
                              <div className="admin-dashboard-divider"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line6">
                              <div className="admin-dashboard-divider6"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line6">
                              <div className="admin-dashboard-divider6"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line6">
                              <div className="admin-dashboard-divider6"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line6">
                              <div className="admin-dashboard-divider6"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line6">
                              <div className="admin-dashboard-divider6"></div>
                            </div>
                            <div className="admin-dashboard-y-axis-line6">
                              <div className="admin-dashboard-divider6"></div>
                            </div>
                          </div>
                          <div className="admin-dashboard-x-axis">
                            <div className="admin-dashboard-jan">Jan</div>
                            <div className="admin-dashboard-jan">Feb</div>
                            <div className="admin-dashboard-mar">Mar</div>
                            <div className="admin-dashboard-jan">Apr</div>
                            <div className="admin-dashboard-may">May</div>
                            <div className="admin-dashboard-jun">Jun</div>
                            <div className="admin-dashboard-jul">Jul</div>
                            <div className="admin-dashboard-mar">Aug</div>
                            <div className="admin-dashboard-mar">Sep</div>
                            <div className="admin-dashboard-jan">Oct</div>
                            <div className="admin-dashboard-mar">Nov</div>
                            <div className="admin-dashboard-mar">Dec</div>
                          </div>
                          <img
                            className="admin-dashboard-chart-data-icon"
                            alt=""
                            src={chart_data2x}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="admin-dashboard-section">
                    <div className="admin-dashboard-container1">
                      <div className="admin-dashboard-metric-group">
                        <div className="admin-dashboard-metric-item">
                          <div className="admin-dashboard-heading1">Today’s revenue</div>
                          <div className="admin-dashboard-number-and-chart">
                            <div className="admin-dashboard-number-and-badge">
                              <div className="admin-dashboard-number">$1,280</div>
                              <div className="admin-dashboard-change-and-text">
                                <div className="admin-dashboard-change">
                                  <img
                                    className="admin-dashboard-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="admin-dashboard-change1">15%</div>
                                </div>
                                <div className="admin-dashboard-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="admin-dashboard-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="admin-dashboard-dropdown">
                            <img
                              className="admin-dashboard-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="admin-dashboard-metric-item">
                          <div className="admin-dashboard-heading1">Today’s orders</div>
                          <div className="admin-dashboard-number-and-chart">
                            <div className="admin-dashboard-number-and-badge">
                              <div className="admin-dashboard-heading">14</div>
                              <div className="admin-dashboard-change-and-text1">
                                <div className="admin-dashboard-change2">
                                  <img
                                    className="admin-dashboard-upload-04-icon"
                                    alt=""
                                    src={arrowdown}
                                  />

                                  <div className="admin-dashboard-change1">10%</div>
                                </div>
                                <div className="admin-dashboard-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="admin-dashboard-chart-mini-icon"
                              alt=""
                              src={chart_mini1_2x}
                            />
                          </div>
                          <div className="admin-dashboard-dropdown">
                            <img
                              className="admin-dashboard-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                        <div className="admin-dashboard-metric-item">
                          <div className="admin-dashboard-heading1">Avg. order value</div>
                          <div className="admin-dashboard-number-and-chart">
                            <div className="admin-dashboard-number-and-badge">
                              <div className="admin-dashboard-number">$91.42</div>
                              <div className="admin-dashboard-change-and-text">
                                <div className="admin-dashboard-change">
                                  <img
                                    className="admin-dashboard-upload-04-icon"
                                    alt=""
                                    src={arrowup}
                                  />

                                  <div className="admin-dashboard-change-amount">20%</div>
                                </div>
                                <div className="admin-dashboard-change-duration">last mth</div>
                              </div>
                            </div>
                            <img
                              className="admin-dashboard-chart-mini-icon"
                              alt=""
                              src={chart_mini2x}
                            />
                          </div>
                          <div className="admin-dashboard-dropdown">
                            <img
                              className="admin-dashboard-dots-vertical-icon"
                              alt=""
                              src={dotsvertical}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              {/* <div className="admin-dashboard-separator"></div>
              <div className="admin-dashboard-separator1"></div> */}
              <div className="admin-dashboard-main-wrapper">
                <div className="admin-dashboard-page-header-wrapper">
                  <div className="admin-dashboard-page-header1">
                    <div className="admin-dashboard-breadcrumbs1">
                      <div className="admin-dashboard-tabs1">
                        <div className="admin-dashboard-breadcrumb-button-base">
                          <img
                            className="admin-dashboard-search-lg-icon"
                            alt=""
                            src={homeline1}
                          />
                        </div>
                        <img
                          className="admin-dashboard-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="admin-dashboard-breadcrumb-button-base6">
                          <div className="admin-dashboard-text42">Settings</div>
                        </div>
                        <img
                          className="admin-dashboard-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="admin-dashboard-breadcrumb-button-base7">
                          <div className="admin-dashboard-text20">...</div>
                        </div>
                        <img
                          className="admin-dashboard-chevron-right-icon"
                          alt=""
                          src={chevronright}
                        />

                        <div className="admin-dashboard-breadcrumb-button-base3">
                          <div className="admin-dashboard-text21">Another link</div>
                        </div>
                        <img
                          className="admin-dashboard-chevron-right-icon2"
                          alt=""
                          src={chevronright}
                        />

                        <div className="admin-dashboard-breadcrumb-button-base4">
                          <div className="admin-dashboard-text34">Team</div>
                        </div>
                      </div>
                    </div>

                    {/* USers Table  */}

                  <AdminDashboardUserComponent/>

                  {/* Business Table  */}

                  <AdminDashboardBusinessComponent/>


                    <div className="admin-dashboard-content15">
                      <div className="admin-dashboard-text-and-supporting-text1">
                        <div className="admin-dashboard-text46"> Listings</div>
                        <div className="admin-dashboard-supporting-text2">
                          
                          All the listings are here
                        </div>
                      </div>
                      {/* <div className="admin-dashboard-input-dropdown1">
                        <div className="admin-dashboard-input-with-label1">
                          <div className="admin-dashboard-label">Search</div>
                          <div className="admin-dashboard-input1">
                            <div className="admin-dashboard-content16">
                              <img
                                className="admin-dashboard-search-lg-icon"
                                alt=""
                                src={searchlg}
                              />

                              <div className="admin-dashboard-text47">Search</div>
                            </div>
                          </div>
                        </div>
                        <div className="admin-dashboard-hint-text1">
                          This is a hint text to help user.
                        </div>
                      </div> */}
                    </div>
                    <img className="admin-dashboard-divider-icon1" alt="" src={divider1} />
                  </div>


                </div>
                <div className="admin-dashboard-supporting-text-wrapper">
                  <div className="admin-dashboard-supporting-text3">
                    Here's to new beginnings! 💰
                  </div>

                  {/* <div className="admin-dashboard-buttonsbutton5a" ref={newdropdownRef} onClick={toggleNewDropdown} style={{ cursor: 'pointer' }}>
                    <img className="admin-dashboard-search-lg-icon1" alt="" src={plus} />

                    <div className="admin-dashboard-text-padding15">
                      <div className="admin-dashboard-text74">Add Listings</div>
                    </div>
                 
                  </div> */}

                </div>
                {/* <div className="admin-dashboard-horizontal-tabs-wrapper">
                  <div className="admin-dashboard-horizontal-tabs">
                    <div className="admin-dashboard-tabs2">
                      <div className={activeTab === 'device' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-tab-name" style={{ cursor: "pointer" }} onClick={() => setActiveTab('device')}>Devices</div>
                      </div>
                      <div className={activeTab === 'spare' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text48" style={{ cursor: "pointer" }} onClick={() => setActiveTab('spare')}>Spares</div>
                      </div>
                      <div className={activeTab === 'service' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text49" style={{ cursor: "pointer" }} onClick={() => setActiveTab('service')}>Service</div>
                      </div>
                      <div className={activeTab === 'software' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text50" style={{ cursor: "pointer" }} onClick={() => setActiveTab('software')}>Software</div>
                      </div>
                      <div className={activeTab === 'job' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text51" style={{ cursor: "pointer" }} onClick={() => setActiveTab('job')}>Jobs</div>
                      </div>
                     
                      <div className="admin-dashboard-tab-button-base5">
                        <div className="admin-dashboard-text52">Billing</div>
                      </div>
                      <div className="admin-dashboard-tab-button-base5">
                        <div className="admin-dashboard-text52">Email</div>
                      </div>
                      <div className="admin-dashboard-tab-button-base7">
                        <div className="admin-dashboard-text52">Notifications</div>
                        <div className="admin-dashboard-badge10">
                          <div className="admin-dashboard-text55">2</div>
                        </div>
                      </div>
                      <div className="admin-dashboard-tab-button-base5">
                        <div className="admin-dashboard-text52">Integrations</div>
                      </div>
                      <div className="admin-dashboard-tab-button-base9">
                        <div className="admin-dashboard-text52">API</div>
                      </div>
                    </div>
                  </div>
                </div>



                {renderContent()} */}
                
                <AdminDashboardOfferedandWantedTab/>


              </div>





            </div>
          </div>
        </div>
        <img className="admin-dashboard-divider-icon6" alt="" src={divider3} />

        <Footer />


      </div>
    )
  }
  else {
    return <Loader />
  }


}

