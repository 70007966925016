import React, { useEffect, useRef, useState } from 'react';
import "./InvitedTeamMemberTableComponent.css";
import divider3 from '../../../icons/Gerator/sales_iq/divider3.svg';
import arrowdown from '../../../icons/Gerator/dashboard/arrowdown.svg';
import dotsvertical from '../../../icons/Gerator/sales_iq/dotsvertical.svg';
import arrowdown1 from '../../../icons/Gerator/dashboard/arrowdown1.svg';
import SkeletonTableListingLoaderComponent from '../../SkeletonTableListingLoaderComponent/SkeletonTableListingLoaderComponent';
import { NoTableDataDisplay } from '../../NoTableDataDisplay/NoTableDataDisplay';
import { ThreeDotsMemberComponent } from '../../ThreeDotsListingComponent/ThreeDotsMemberComponent';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';
import { useLazyGetSentRequestMemberForTeamDashboardQuery } from '../../../api/MemberAPI';
import { getLastSeen } from '../../../helper/DeviceHelper';



export const InvitedTeamMemberTableComponent = ({ currentUser, currentUsermeta }) => {
  const [loadingComplete, setLoadingComplete] = useState(false);
  let content;
  const [offSetSentRequestMember, setOffSetSentRequestMember] = useState(0);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);

  //Query to get SentRequest Member 
  const [triggerGetSentRequestMemberData, { data: currentSentRequestMemberData, isError: isSentRequestMembersDataError, error: currentSentRequestMemberError, isLoading: isSentRequestMembersDataLoading, isSuccess: isSentRequestMembersDataSuccess, isFetching: isSentRequestMemberDataFetching }] = useLazyGetSentRequestMemberForTeamDashboardQuery();

  // const toggleThreeDotsDropdown = (id) => {
  //   if (isClickBlocked) return; // Prevent rapid toggle

  //   if (activeThreeDotId === id) {
  //     setSentRequestThreeDotId(null); // Close if the same one is clicked again
  //     setIsClickBlocked(true);
  //     setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
  //   } else {
  //     setSentRequestThreeDotId(id); // Open the clicked one
  //   }
  // };

  useEffect(() => {
    if (currentUser) {
      triggerGetSentRequestMemberData({ offSetSentRequestMember },
        {
          // Refetch when component mounts or tab becomes active
          refetchOnMountOrArgChange: true
        });
    }
  }, [currentUser, offSetSentRequestMember]);


  useEffect(() => {
    if (currentSentRequestMemberData) {
      console.log(currentSentRequestMemberData);
    }
  }, [currentSentRequestMemberData])



  const handleThreeDotsClick = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveThreeDotId(activeThreeDotId === index ? null : index);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        threeDotsRef.current &&
        !threeDotsRef.current.contains(event.target) &&
        (!dropdownRef.current || !dropdownRef.current.contains(event.target))
      ) {
        setActiveThreeDotId(null);
        setIsClickBlocked(false); // Reset blocked state when closing
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsClickBlocked(false); // Reset on cleanup
    };
  }, []);


  const handleSentRequestMemberDataLoadMore = () => {
    if (currentSentRequestMemberData?.hasMore && !isSentRequestMemberDataFetching) {
      setOffSetSentRequestMember(currentOffset => currentOffset + 5);
    }
  };

  const showSentRequestMemberDataLoadMore = currentSentRequestMemberData?.hasMore &&
    currentSentRequestMemberData.sentRequestMembers.length < currentSentRequestMemberData.totalCount &&
    !isSentRequestMemberDataFetching;

  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSentRequestMembersDataSuccess) {

      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSentRequestMembersDataSuccess]);

  if (isSentRequestMembersDataError) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (isSentRequestMembersDataLoading) {
    content = <SkeletonTableListingLoaderComponent />;
  } else if (loadingComplete && isSentRequestMembersDataSuccess) {

    if (currentSentRequestMemberData?.sentRequestMembers.length > 0) {
      // currentSentRequestMemberData.sentRequestMembersLength = currentSentRequestMemberData.sentRequestMembers.length;
      content = currentSentRequestMemberData.sentRequestMembers.map((data, index) =>
      (
        <div className="team-member-table-table-header-cell-parent" key={index}>

          <div className="team-member-table-table-cell10-Invited">

            {/* <div className="team-member-table-cell-content-Invited">
                                  <input className="team-member-table-checkbox-wrapper" type="checkbox" />
                                </div> */}

            <div className="team-member-table-avatar-Invited" style={{
              backgroundImage: data.profileImage
                && `url(${data.profileImage})`
            }}>
              {/* <div className="team-member-table-contrast-border">
                                        <div className="team-member-table-avatar-online-indicator"></div>
                                    </div> */}
            </div>

            <div className="team-member-table-dropdown1-Invited">
              <div className="team-member-table-cell-title-Invited">{data.displayName}</div>
              {/* <div className="team-member-table-supporting-text4-Invited">{data.user_usermeta?.userWorkTitle || 'Unknown'}</div> */}
            </div>
          </div>

          <div className="team-member-table-table-cell20-Invited">
            <div className="team-member-table-location-content">
              <div className="team-member-table-text36">{data.email}</div>
            </div>
          </div>

          <div className="team-member-table-table-cell30-Invited">
            <div className="team-member-table-location-content">
              <div className="team-member-table-text36">{data.userInvitationStatus.name}</div>
            </div>
          </div>

          <div className="team-member-table-table-cell30-Invited">
            <div className="team-member-table-location-content">


              <div className="team-member-table-text36">{data.updatedAt}</div>
            </div>
          </div>



          <div className="team-member-table-table-cell40-Active" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleThreeDotsClick(e, index);
          }}
            ref={threeDotsRef}>
            <div className="team-member-table-location-content" >

              <img
                className="team-member-table-dots-vertical-icon"
                alt=""
                src={dotsvertical}
              />
              {/* <div className="team-member-table-text36">
                                           
                                          </div> */}
              {activeThreeDotId === index && (
                <div ref={dropdownRef}>
                  <ThreeDotsMemberComponent
                    option={data.userInvitationStatus.slug}
                    userId={data.id}
                    onClose={() => {
                      setActiveThreeDotId(null);
                      setIsClickBlocked(false);
                    }}
                  />
                </div>
              )}
            </div>

          </div>

        </div>

      ))
    }
    else {
      content = (
        <NoTableDataDisplay />
      )
    }

  } else if (!loadingComplete && isSentRequestMembersDataSuccess) {
    content = <SkeletonTableListingLoaderComponent />; // Show loader until loadingComplete is true
  }

  return (
    <>


      <div className="team-member-table-display-form-content-wrapper-Invited">
        <div className="team-member-table-display-form-content-Invited">
          <div className="team-member-table-display-toggle-header-parent-Invited">
            <div className="team-member-table-display-toggle-header-Invited">
              {/* <div className="team-member-table-display-toggle-title-Invited">Pending</div> */}
              {/* <PlanExpiryStatus expiryDate={currentPlanExpiry} planName={currentPlanName} /> */}
            </div>

          </div>
          {/* <div className="team-member-table-display-divider-container-Invited">
                        <img
                        className="team-member-table-display-divider-icon3-Invited"
                        alt=""
                        src={divider3}
                        />
                    </div> */}

          <form className="team-member-table-table-Invited">

            <div className="team-member-table-table-content-Invited">

              <div className="team-member-table-table-row-Invited">

                {/* Invoice */}



                <div className="team-member-table-table-header-cell1-Invited">
                  {/* <input className="team-member-table-checkbox-wrapper" type="checkbox" /> */}
                  <div className="team-member-table-table-header-Invited">
                    <a className="team-member-table-column-title-Invited">Name</a>
                    {/* <img
                                className="team-member-table-arrow-down-icon1"
                                alt=""
                                src={arrowdown}
                                /> */}
                  </div>
                </div>


                {/* Location */}

                <div className="team-member-table-table-header-cell2-Invited">
                  <div className="team-member-table-table-header-Invited">
                    <a className="team-member-table-column-title-Invited">Email</a>

                  </div>
                </div>


                {/* Role  */}

                <div className="team-member-table-table-header-cell3-Invited">
                  <div className="team-member-table-table-header-Invited">
                    <a className="team-member-table-column-title-Invited">Status</a>


                  </div>
                </div>

                {/* Last seen  */}

                <div className="team-member-table-table-header-cell3-Invited">
                  <div className="team-member-table-table-header-Invited">
                    <a className="team-member-table-column-title-Invited">Date & Time</a>


                  </div>
                </div>

                {/* Price  */}

                {/* <div className="team-member-table-table-header-cell4">
                            <div className="team-member-table-table-header">
                                <a className="team-member-table-column-title">Price</a>


                            </div>
                            </div> */}

                {/* Action  */}

                <div className="team-member-table-table-header-cell4-Invited">
                  <div className="team-member-table-table-header2-Invited">
                    <div className="team-member-table-action-column-title-Invited">Action</div>
                    <img
                      className="team-member-table-arrow-down-icon2-Invited"
                      alt=""
                      src={arrowdown}
                    />
                  </div>
                </div>

              </div>



            </div>

            <div className='team-member-table-table-data-custom-main-container'>

              {content}
            </div>

            {/* Load More  */}
            {showSentRequestMemberDataLoadMore || undefined && (
              <div className="team-member-table-frame-wrapper44">
                <div className="team-member-table-frame-parent72">
                  <div className="team-member-table-buttonsbutton-wrapper1">

                    <button className='team-member-table-buttonsbutton-wrapper1-child'
                      onClick={handleSentRequestMemberDataLoadMore}
                      disabled={isSentRequestMemberDataFetching}
                    >
                      <div className="team-member-table-buttonsbutton28">
                        <img
                          className="team-member-table-arrow-narrow-left-icon"
                          alt=""
                          src={arrowdown1}
                        />
                        <div className="team-member-table-search-input">
                          <div className="team-member-table-text146">{isSentRequestMemberDataFetching ? 'Loading...' : 'Load More'}</div>
                        </div>
                      </div>
                    </button>

                  </div>
                </div>
              </div>
            )}



          </form>


        </div>
      </div >
    </>
  )
}

