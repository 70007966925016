import React, { useEffect, useRef, useState } from 'react';
import "./UserProfile.css";
import { Link, useNavigate } from 'react-router-dom';
// import { AccountSetupButton } from '../../../components/AccountSetupButton/AccountSetupButton';
import gerator_logo from '../../icons/Gerator/gerator04-1@2x.png';
// import dashboard_divider from '../../icons/Gerator/dashboard/divider.svg';
import dashboard_divider3 from "../../icons/Gerator/dashboard/divider3.svg"
import feature_icon1 from '../../icons/Gerator/account_setup/featured-icon-1.svg';
import feature_icon2 from '../../icons/Gerator/account_setup/featured-icon-2.svg';
import feature_icon3 from '../../icons/Gerator/account_setup/featured-icon-3.svg';
import feature_icon4 from '../../icons/Gerator/account_setup/featured-icon-4.svg';
import mail_icon from '../../icons/Gerator/account_setup_profile_subscription/mail01-1.svg';
import divider from '../../icons/Gerator/account_setup_profile_subscription/divider.svg';
import { BackToLoginButton } from '../../components/BackToLoginButton/BackToLoginButton';
// import { auth, onAuthStateChanged } from "../../Login/FirebaseClient";
import { useGetCountryTaxQuery } from '../../api/TaxonomyFormAPI';
import { Provider } from "./UserProfileContext";
import { useLazyGetUserProfileQuery } from '../../api/UsermetaAPI';
import { firestore, doc, getDoc, auth, onAuthStateChanged } from '../Login/FirebaseClient';
import UserProfileForm from '../../components/UserProfileFormComponent/UserProfileForm';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Footer } from '../../components/Footer/Footer';
import { Navbar1 } from '../../components/Navbar1/Navbar1';
import { Loader } from '../../components/Loader';
import { decryptData, decryptErrorData } from '../../helper/AESHelper';
// import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';
import { SideBarSubSettings } from '../../components/SideBars-Sub/SideBarSubSettings/SideBarSubSettings';
import { useLazyGetProfileImageLinkQuery } from '../../api/Login&RegisterAPI';
import React from 'react';
import { ErrorShowComponent } from '../../components/ErrorShowComponent/ErrorShowComponent';
import { SideBarSubNormal } from '../../components/SideBars-Sub/SideBarSubNormal/SideBarSubNormal';



export const UserProfile = () => {
  // const [getProfile, { data, isLoading:isLoadingGetProfile, isError:isErrorGetProfile, error:errorGetProfile, isSuccess:isSuccessGetUser }] = useLazyGetUserProfileQuery();

  const [triggerGetProfile, { data, isLoading, isError, error }] = useLazyGetUserProfileQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();
  let content;
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [backendValidation, setBackendValidation] = useState([]);
  const [selectedImageFile, setSelectedImageFile] = useState("");
  const [cropedImageFile, setCropedImageFile] = useState("");
  const [profileImageObject, setProfileImageObject] = useState("");
  const formikRef = useRef(null);
  const countryTax = useGetCountryTaxQuery();

  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const navigate = useNavigate();
  const [allTaxonomy, setAllTaxonomy] = useState({ country: countryTax })

  useEffect(() => {

    const allSuccess = [countryTax.isSuccess,].every(Boolean);


    if (allSuccess) {
      setAllTaxonomy({
        country: countryTax
      });
    }
  }, [
    countryTax.isSuccess, countryTax.data,
  ]);



  // useEffect(() => {
  //   // const auth = getAuth();

  //   // Listen for authentication state change
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       // User is authenticated
  //       setCurrentUser(user);
  //       triggerGetProfile();
  //     } else {
  //       // User is not authenticated, redirect to login
  //       navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
  //     }
  //   });

  //   return () => unsubscribe(); // Cleanup listener on component unmount
  // }, [navigate, triggerGetProfile]);


  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            // await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            triggerGetProfile();

            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });

    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);


  // if (isLoading) {
  //   return <Loader />; // Display loader when query is loading
  // }

  if (error) {
    content = <ErrorShowComponent message1={'Internal server error'} />
  }

  if (data) {
    content = <>
      <Provider value={{ profileImageObject, setProfileImageObject, allTaxonomy, backendValidation, setBackendValidation, cropedImageFile, setCropedImageFile, selectedImageFile, setSelectedImageFile }}>
        <UserProfileForm user={data.user} formikRef={formikRef} setShowSuccessMessage={setShowSuccessMessage} currentUsermeta={currentUserMeta} />
      </Provider>
    </>
  }


  if (currentUser) {
    return (
      <div className="dashboard-dashboard-frame-layout">
        <div className="dashboard-dashboard-frame-layout-child"></div>

        <Navbar1 userData={currentUser} navigate={navigate} profileImageLink={profileImageLink} />


        <div className="dashboard-all dashboard-all-custom-padding">
          <img className="dashboard-divider-icon" alt="" src={dashboard_divider3} />

          <div className="dashboard-all-content dashboard-all-content-custom-width">

            <div className="account-update-profile-account-update-profile account-update-profile-account-update-profile-custom-align-item">
              <div className="account-update-profile-section2 account-update-profile-section2-custom-minwidth-padding">
                <div className="account-update-profile-progress-container-wrapper account-update-profile-progress-container-wrapper-custom-padding-width">

                  <Sidebar profileImageLink={profileImageLink}>
                    <SideBarSubNormal userData={currentUser} />
                  </Sidebar>
                </div>

              </div>
              <section className="account-update-profile-content-wrapper-wrapper">
                <div className="account-update-profile-content-wrapper-custom">


                  <div className="account-update-profile-header-section account-update-profile-header-section-custom-margin">
                    <div className="account-update-profile-container3">
                      <div className="account-update-profile-page-header">

                        <div className="account-update-profile-content7">
                          <div className="account-update-profile-text-and-supporting-text4">
                            <a className="account-update-profile-page-title">Profile</a>
                            <div className="account-update-profile-supporting-text13">
                              Time to work smarter! 💡
                            </div>
                          </div>
                          {/* <div className="account-update-profile-actions4">
                    <div className="account-update-profile-button">
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder2}
                      />
  
                      <div className="account-update-profile-text-padding8">
                        <div className="account-update-profile-text45">Tertiary</div>
                      </div>
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder2}
                      />
                    </div>
                    <div className="account-update-profile-button1">
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder3}
                      />
  
                      <div className="account-update-profile-text-padding8">
                        <div className="account-update-profile-text45">Secondary</div>
                      </div>
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder3}
                      />
                    </div>
                    <div className="account-update-profile-button2">
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder1}
                      />
  
                      <div className="account-update-profile-text-padding8">
                        <div className="account-update-profile-text45">Secondary</div>
                      </div>
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder1}
                      />
                    </div>
                    <div className="account-update-profile-button3">
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder4}
                      />
  
                      <div className="account-update-profile-text-padding8">
                        <div className="account-update-profile-text45">Primary</div>
                      </div>
                      <img
                        className="account-update-profile-placeholder-icon16"
                        alt=""
                        src={placeholder4}
                      />
                    </div>
                  </div>
                  <div className="account-update-profile-input-dropdown">
                    <div className="account-update-profile-input-with-label">
                      <div className="account-update-profile-label">Search</div>
                      <div className="account-update-profile-input">
                        <div className="account-update-profile-content8">
                          <img
                            className="account-update-profile-home-line-icon"
                            alt=""
                            src={searchlg}
                          />
  
                          <div className="account-update-profile-text49">Search</div>
                        </div>
                      </div>
                    </div>
                    <div className="account-update-profile-hint-text">
                      This is a hint text to help user.
                    </div>
                  </div> */}
                        </div>
                        <img className="account-update-profile-divider-icon" alt="" src={divider} />
                      </div>
                    </div>
                  </div>
                  <div className="account-update-profile-content-elements-wrapper account-update-profile-content-elements-wrapper-custom-padding">
                    {content}
                  </div>


                  {showSuccessMessage.message && (
                    <div
                      style={{
                        fontSize: "14px",
                        color: showSuccessMessage.color,
                        padding: "15px 10px",
                        border: "1px solid " + showSuccessMessage.color,
                        borderRadius: "10px",
                        margin: "0 auto",
                      }}
                    >
                      {showSuccessMessage.message}
                    </div>
                  )}


                </div>
              </section>
            </div>

          </div>

        </div>

        <img className="dashboard-divider-icon6" alt="" src={dashboard_divider3} />

        <Footer />


      </div>


    )
  } else {
    return <Loader />
  }

}

