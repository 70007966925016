import React, {useState} from 'react';
import "./StageSlideOutForm.css";
import xclose from "../../icons/Gerator/slide_out/xclose.svg";
import help_icon from "../../icons/Gerator/slide_out/help-icon.svg";



import funnel from "../../icons/Gerator/dashboard/funnel.svg"
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import dollar from "../../icons/Gerator/add_form/dollar.svg";


export const StageSlideOutForm = ({onClose}) => {

  

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedStage, setSelectedStage] = useState("stage0");
  const [percentage, setPercentage] = useState(0);
  
  const stageRanges = {
    stage0: { min: 0, max: 0 },    
    stage1: { min: 1, max: 15 },    
    stage2: { min: 16, max: 30 },   
    stage3: { min: 31, max: 70 },  
    stage4: { min: 71, max: 90 },   
    stage5: { min: 100, max: 100 },
    stage6: { min: 0, max: 0 }, 
  };

  const handleStageChange = (event) => {
    const stage = event.target.value;
    setSelectedStage(stage);

    // Clear percentage when stage changes, except for fixed stages
    if (stage === "stage0" || stage === "stage5" || stage === "stage6") {
      setPercentage(stageRanges[stage].min); // Set fixed value
    } else {
      setPercentage(""); // Clear input for other stages
    }
  };

  const handlePercentageChange = (event) => {
    const value = parseInt(event.target.value, 10);

    // Validate percentage within the current stage's range
    const { min, max } = stageRanges[selectedStage];
    if (value >= min && value <= max) {
      setPercentage(value);
    }
  };
  

  return (
    <div className="stage-slide-out-menu">
      <main className="stage-panel">
        <section className="stage-slide-out-menu-header-parent">
          <header className="stage-slide-out-menu-header">
            <div className="stage-content71">
              <img
                className="stage-featured-icon2"
                loading="lazy"
                alt=""
                src={funnel}
              />

              <div className="stage-text-and-supporting-text26">
                <a className="stage-title9">Update Stage</a>
                <div className="stage-supporting-text91">
                  Add a line here
                </div>
              </div>
            </div>
            <div className="stage-button-close-x6" onClick={onClose} >
              <img
                className="stage-mail-01-icon"
                loading="lazy"
                alt=""
                src={xclose}
              />
            </div>
          </header>
          <div className="stage-section-container">
            <div className="stage-section2">
              <div className="stage-input-field">
                <div className="stage-input-field">
                  <div className="stage-label9">Stage</div>
                  <select className="stage-input6a"  value={selectedStage} onChange={handleStageChange}>
                  <option className="stage-content72" value="stage0">Stage 0 - In frozen state</option>
                  <option className="stage-content72" value="stage1">Stage 1 - Understanding requirements</option>
                  <option className="stage-content72" value="stage2">Stage 2 - Discussions/evaluations in progress</option>
                  <option className="stage-content72" value="stage3">Stage 3 - Tweaks/Follow-ups</option>
                  <option className="stage-content72" value="stage4">Stage 4 - Commercial Negotiation</option>
                  <option className="stage-content72" value="stage5">Stage 5 - Deal Won</option>
                  <option className="stage-content72" value="stage6">Stage 6 - Deal Lost</option>

                    {/* <input
                      className="stage-content72"
                      placeholder="Olivia Rhye"
                      type="text"
                    />

                    <img
                      className="stage-help-icon2"
                      alt=""
                      src={help_icon}
                    /> */}
                  </select>
                </div>
              
              </div>
              <div className="stage-form">
                <div className="stage-input-field">
                  <div className="stage-input-field">
                    <div className="stage-label10">Probability of closure</div>
                    
                    <div className="stage-input6">
                      <div className="stage-content73">

                      {selectedStage === "stage0" || selectedStage === "stage5" || selectedStage === "stage6" ? (
              // Fixed percentage for stage 0 and stage 5
                              <input
                                className="stage-content72"
                                type="text"
                                value={`${percentage}%`}
                                readOnly
                              />
                            ) : (
                              // Editable percentage input for intermediate stages
                              <input
                                className="stage-content72"
                                type="number"
                                value={percentage}
                                onChange={handlePercentageChange}
                                min={stageRanges[selectedStage].min}
                                max={stageRanges[selectedStage].max}
                                placeholder={` (${stageRanges[selectedStage].min}-${stageRanges[selectedStage].max}%)`}
                              />
                            )}

                   
                      </div>
                     
                    </div>
                    {/* <div className="stage-email-domain">
                        @refurbish-med.com
                        </div> */}
                   
                   
                  </div>
                  {/* <div className="stage-hint-text6">
                  @refurbish-med.com
                  </div> */}
                </div>
              </div>

              
              <div className="stage-input-field">
                <div className="stage-input-field">
                  <div className="stage-label11">Timeline to closure</div>
                  <div className="stage-input6">

                  <DatePicker
                      className="stage-content72"
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      placeholderText="Select a date"
                      minDate={new Date()} // Restrict past dates
                      dateFormat="dd-MM-yyyy" // Format the date
                    />

                  </div>
                </div>
                <div className="stage-hint-text6">This is a hint text to help user.</div>
              </div>
              <div className="stage-input-field">
                <div className="stage-input-field">
                  <div className="stage-label12">Transaction Value</div>
                  <div className="stage-input6">
                      

                      
                    <img
                      className="stage-help-icon2"
                      alt=""
                      src={dollar}
                    /> 

                    <input
                      className="stage-content72"
                      placeholder="Value in USD"
                      type="text"
                    />

                  </div>
                </div>
                <div className="stage-hint-text6">This is a hint text to help user.</div>
              </div>

              <div className="stage-input-field">
                <div className="stage-input-field">
                  <div className="stage-label12">Comment</div>
                  <div className="stage-input6">
                      

                  

                    <textarea
                      className="stage-content72"
                      placeholder="Max 300 characters"
                      type="text"
                       maxlength="300"
                    />

                  </div>
                </div>
                
              </div>

            </div>
          </div>


          <div className="stage-divider-wrapper">
            <div className="stage-divider12"></div>
          </div>
        </section>
        <footer className="stage-footer2">
          <div className="stage-content76">
            <div className="stage-actions19">
              <button className="stage-buttonsbutton49">
                <img
                  className="stage-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />

                <div className="stage-text-padding51">
                  <div className="stage-text275">Cancel</div>
                </div>
                <img
                  className="stage-placeholder-icon91"
                  alt=""
                  src="./public/placeholder2.svg"
                />
              </button>
              <button className="stage-buttonsbutton50">
                <img
                  className="stage-placeholder-icon91"
                  alt=""
                  src="./public/placeholder3.svg"
                />

                <div className="stage-text-padding51">
                  <div className="stage-text276">Update</div>
                </div>
                <img
                  className="stage-placeholder-icon91"
                  alt=""
                  src="./public/placeholder3.svg"
                />
              </button>
            </div>
          </div>
        </footer>
      </main>
    </div>
  )
}

