import React, { useEffect, useRef, useState } from 'react';
import "../FunnelSpareComponent/FunnelSpareComponent.css";
import dotsvertical from '../../../icons/Gerator/dashboard/dotsvertical.svg';
import calendar1 from '../../../icons/Gerator/dashboard/calendar1.svg';
import divider2 from '../../../icons/Gerator/dashboard/divider2.svg';
import group_58_2x from '../../../icons/Gerator/dashboard/group-58@2x.png';
import eye from '../../../icons/Gerator/dashboard/eye.svg';
import icon1 from '../../../icons/Gerator/dashboard/icon1.svg';
import lock04 from '../../../icons/Gerator/dashboard/lock04.svg';
import lockunlocked04 from '../../../icons/Gerator/dashboard/lockunlocked04.svg';
import arrowsquareupright from '../../../icons/Gerator/dashboard/arrowsquareupright.svg';
import messagechatsquare from '../../../icons/Gerator/dashboard/messagechatsquare.svg';
import sliders02 from '../../../icons/Gerator/dashboard/sliders02.svg';
import placeholder2 from '../../../icons/Gerator/dashboard/placeholder2.svg';
import arrowdown1 from '../../../icons/Gerator/sales_iq/arrowdown1.svg';
import SearchInputListingComponent from '../../SearchInputListingComponent/SearchInputListingComponent';
import { SortByFilterComponent } from '../../SortByFilterComponent/SortByFilterComponent';
import SkeletonListingLoaderComponent from '../../SkeletonListingLoaderComponent/SkeletonListingLoaderComponent';

// import { NoListingFound } from '../../NoListing/NoListingFound';
import {NoTableDataDisplay} from "../../NoTableDataDisplay/NoTableDataDisplay";
import { useGetSalesIqDashboardFunnelWantedSparesQuery } from '../../../api/Funnel/FunnelWantedSpareAPI';
import { getCustomizedDate } from '../../../helper/SpareHelper';
import { Link } from 'react-router-dom';
import { ThreeDotsListingComponent } from '../../ThreeDotsListingComponent/ThreeDotsListingComponent';
import { ErrorShowComponent } from '../../ErrorShowComponent/ErrorShowComponent';


export const FunnelWantedSpareCard = () => {


  //Offset to load data when click load more
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('date_desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [totalCount, setTotalCount] = useState("");
  const [totalCountWantedSpare, settotalCountWantedSpare] = useState("");
  const [totalCountWantedService, settotalCountWantedService] = useState("");
  const [totalCountWantedSoftware, settotalCountWantedSoftware] = useState("");
  const [activePostStatus, setActivePostStatusFilter] = useState(null);

  //Query to get data
  const { data, isSuccess, isError, refetch, isLoading, isFetching } = useGetSalesIqDashboardFunnelWantedSparesQuery(
    { offset, sortBy, postStatus: activePostStatus, search: searchTerm },
    {
      // Refetch when component mounts or tab becomes active
      refetchOnMountOrArgChange: true
    }
  );

  //flag var to show listing when loading complete when sekelteon layout is going to stop
  const [loadingComplete, setLoadingComplete] = useState(false);

  //Var is reponsible to show listings and other thing as well
  let content;

  //Sort by text show on button
  const [selectedSortOption, setSelectedSortOption] = useState('');

  //For SortBy Dropdown
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  //For Threedots in listing dropdown
  const [activeThreeDotId, setActiveThreeDotId] = useState(null);
  const threeDotsRef = useRef(null);
  const [isClickBlocked, setIsClickBlocked] = useState(false);



  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  }

  const toggleThreeDotsDropdown = (id) => {
    if (isClickBlocked) return; // Prevent rapid toggle

    if (activeThreeDotId === id) {
      setActiveThreeDotId(null); // Close if the same one is clicked again
      setIsClickBlocked(true);
      setTimeout(() => setIsClickBlocked(false), 400); // Small delay to avoid reopening
    } else {
      setActiveThreeDotId(id); // Open the clicked one
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };


  //Set value for sortby selected option on button text
  const handleOptionSelect = (option) => {
    if (option.value !== sortBy) {
      setSortBy(option.value);
      setOffset(0); // Reset offset when sort option changes
    }
    setSelectedSortOption(option);
    setDropdownVisible(false);
  };

  //DashbaordFilterButtonGroup component to get data
  const handleFilterClick = (filterLabel) => {
    if (activePostStatus === filterLabel) {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(null);
    } else {
      setSortBy('date_desc')
      setSelectedSortOption('')
      setActivePostStatusFilter(filterLabel);
    }
    setOffset(0); // Reset offset when changing filters
  };

  //Setting offset for loadmore when user clicked on load more button(this trigger query to fetch new data)
  // Reset offset when component mounts or remounts
  // useEffect(() => {
  //   setOffset(0);
  // }, []);
  // // Reset offset when sort option changes
  // useEffect(() => {
  //   setOffset(0);
  // }, [sortBy]);

  const handleLoadMore = () => {
    if (data?.hasMore && !isFetching) {
      setOffset(currentOffset => currentOffset + 5);
    }
  };

  const showLoadMore = data?.hasMore &&
    data.funnel_wanted_spare.length < data.totalCount &&
    !isFetching;





  //Onclick listener for sortby dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  //Onclick listener for threedots dropdown
  useEffect(() => {
    const handleThreeDotClickOutside = (event) => {
      if (threeDotsRef.current && !threeDotsRef.current.contains(event.target)) {
        setActiveThreeDotId(null); // Close any open dropdown when clicking outside
      }
    };

    document.addEventListener("mousedown", handleThreeDotClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleThreeDotClickOutside);
    };
  }, []);

  // Listen for tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [refetch]);


  //Success true then pause to show sekelton layout
  useEffect(() => {
    if (isSuccess) {
      // // Set totalcount in listing counter layout
      // setTotalCount(data.totalCount);
      // settotalCountWantedSpare(data.totalCountWantedSpare);
      // settotalCountWantedService(data.totalCountWantedService);
      // settotalCountWantedDevice(data.totalCountWantedDevice);
      // Set timeout to delay the loading completion
      const timer = setTimeout(() => {
        setLoadingComplete(true);
      }, 1000); // 1 second

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isSuccess]);


  if (isError) {
    content = <ErrorShowComponent message1={"Internal server error"} />
  }

  if (isLoading) {
    content = <SkeletonListingLoaderComponent />;
  } else if (loadingComplete && isSuccess) {

    if (data.funnel_wanted_spare.length > 0) {
      // dataLength = data.funnel_wanted_spare.length;
      content = data.funnel_wanted_spare.map((wantedDevice, index) =>
      (

        <div className="funnel-spare-component-funnel-content-items" key={index}>
          {/* < div className = "funnel-spare-component-funnel-content-items" > */}
          <div className="funnel-spare-component-funnel-divider-container1">
            <div className="funnel-spare-component-funnel-divider-container-child"></div>
            <div className="funnel-spare-component-funnel-rectangle-parent5">


              <div className="funnel-spare-component-funnel-frame-parent1">
                <div className="funnel-spare-component-funnel-action-fields">
                  <div className="funnel-spare-component-funnel-input-elements">
                    <Link to={`/wanted-spare/${wantedDevice.id}/${wantedDevice.title}`} className="funnel-spare-component-funnel-text78">
                      {wantedDevice.title}
                    </Link>

                    <div className="funnel-spare-component-funnel-frame-parent21">
                      <div className="funnel-spare-component-funnel-badge-item-parent">




                        <div className="funnel-spare-component-funnel-frame-wrapper19">
                          <div className="funnel-spare-component-funnel-legend-series">
                            <div className="funnel-spare-component-funnel-search-input">
                              <img
                                className="funnel-spare-component-funnel-marker-pin-02-icon2"
                                alt=""
                                src={calendar1}
                              />
                            </div>
                            <div className="funnel-spare-component-funnel-type-of-work2">{getCustomizedDate(wantedDevice.createdAt)}</div>
                          </div>
                        </div>

                        <div className="funnel-spare-component-funnel-frame-parent4">
                          <div className="funnel-spare-component-funnel-end-label">
                            <div className="funnel-spare-component-funnel-search-icon-input">4.9/5</div>
                          </div>
                          <div className="funnel-spare-component-funnel-type-support">
                            <div className="funnel-spare-component-funnel-supporting-text9">
                              99,999 reviews
                            </div>
                          </div>
                        </div>






                      </div>



                    </div>
                    <div className="funnel-spare-component-funnel-small-card">

                      <div className="funnel-spare-component-funnel-badge33">
                        <div className="funnel-spare-component-funnel-third-badge-number">
                          {wantedDevice.tax_device_category.name}
                        </div>
                      </div>

                      <div className="funnel-spare-component-funnel-badge36">
                        <div className="funnel-spare-component-funnel-third-badge-number">
                          {wantedDevice.tax_oem.name}
                        </div>
                      </div>

                      <div className="funnel-spare-component-funnel-badge37">
                        <div className="funnel-spare-component-funnel-third-badge-number">{wantedDevice.tax_clinical_application.name}</div>

                      </div>

                      <div className="funnel-spare-component-funnel-badge38">
                        <div className="funnel-spare-component-funnel-third-badge-number">
                          {wantedDevice.tax_purpose_use.name}
                        </div>
                      </div>

                      {/* <div className="funnel-spare-component-funnel-frame-parent24">
                   



                    <div className="funnel-spare-component-funnel-frame-wrapper18">
                    <div className="funnel-spare-component-funnel-badge-elements-copy">
                       
                       
                    </div>
                    </div>
                    </div>
                    <div className="funnel-spare-component-funnel-frame-parent26">

                       
                    </div> */}

                    </div>
                  </div>

                </div>
                <div className="funnel-spare-component-funnel-filter-elements">
                  <div className="funnel-spare-component-funnel-dropdown10" onClick={() => toggleThreeDotsDropdown(index)} ref={threeDotsRef}>
                    <div className="funnel-spare-component-funnel-dropdown10" >

                      <img
                        className="funnel-spare-component-funnel-dots-vertical-icon"
                        alt=""
                        src={dotsvertical}
                      />
                      {activeThreeDotId === index && <ThreeDotsListingComponent url={`wanted-device/${wantedDevice.id}`} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="funnel-spare-component-funnel-divider-icon8" alt="" src={divider2} />

            <div className="funnel-spare-component-funnel-frame-parent71">
              <div className="funnel-spare-component-funnel-buttonsbutton-parent1">
                <div className="funnel-legend-series">
                  <img
                    className="funnel-location-column-child"
                    alt=""
                    src={wantedDevice.profileImage}
                  // src={group_58_2x}
                  />

                  <div className="funnel-location-column-inner">
                    <div className="funnel-date-picker-dropdown">
                      <div className="funnel-name">{wantedDevice.wanted_spare_user.displayName}</div>
                      <div className="funnel-role">{wantedDevice.wanted_spare_user.user_usermeta.userWorkTitle}</div>
                    </div>
                  </div>
                </div>
                <div className='funnel-spare-component-main-buttons-group'>
                  <div className="funnel-spare-component-funnel-buttonsbutton13">
                    <img
                      className="funnel-spare-component-funnel-arrow-narrow-left-icon"
                      alt=""
                      src={eye}
                    />

                    <div className="funnel-spare-component-funnel-search-input">
                      <div className="funnel-spare-component-funnel-text67">View</div>
                    </div>
                  </div>
                  <div className="funnel-spare-component-funnel-buttonsbutton25">
                    <div className="funnel-spare-component-funnel-icon-wrapper">
                      <img className="funnel-spare-component-funnel-icon1" alt="" src={icon1} />
                    </div>
                    <div className="funnel-spare-component-funnel-search-input">
                      <div className="funnel-spare-component-funnel-text68">Sales IQ</div>
                    </div>
                    <img
                      className="funnel-spare-component-funnel-arrow-narrow-left-icon"
                      alt=""
                      src={lock04}
                    />
                  </div>
                  <div className="funnel-spare-component-funnel-buttonsbutton26">
                    <div className="funnel-spare-component-funnel-icon-wrapper">
                      <img className="funnel-spare-component-funnel-icon1" alt="" src={icon1} />
                    </div>
                    <div className="funnel-spare-component-funnel-search-input">
                      <div className="funnel-spare-component-funnel-text68">Sales IQ</div>
                    </div>
                    <img
                      className="funnel-spare-component-funnel-arrow-narrow-left-icon"
                      alt=""
                      src={lockunlocked04}
                    />
                  </div>
                  <div className="funnel-spare-component-funnel-buttonsbutton27">
                    <img
                      className="funnel-spare-component-funnel-upload-04-icon"
                      alt=""
                      src={messagechatsquare}
                    />

                    <div className="funnel-spare-component-funnel-text145">Messages</div>
                    <div className="funnel-spare-component-funnel-ellipse-parent">
                      <div className="funnel-spare-component-funnel-ellipse-div"></div>
                      <div className="funnel-spare-component-funnel-div">2</div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="funnel-spare-component-funnel-arrow-square-up-right-wrapper1">
                <img
                  className="funnel-spare-component-funnel-arrow-square-up-right-icon1"
                  alt=""
                  src={arrowsquareupright}
                />
              </div>
            </div>
          </div>
        </div >

      ))
    }
    else {
      content = <NoTableDataDisplay path={"/add-wanted-spare"} pathName={"Wanted Spare"} />
    }

  } else if (!loadingComplete && isSuccess) {
    content = <SkeletonListingLoaderComponent />; // Show loader until loadingComplete is true
  }

  return (
    <>

      <div className="funnel-section-wrapper">
        <div className="funnel-section1">
          <div className="funnel-container1">
            <div className="funnel-filters-bar">
              <div className="funnel-content17">

                <div className="funnel-input-dropdown2">

                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="funnel-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="funnel-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="funnel-text-padding6" ref={dropdownRef}>
                      <div className="funnel-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="funnel-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div>
                {/* <div className="funnel-input-dropdown2">


                  <SearchInputListingComponent onSearch={handleSearch} />

                  <div className="funnel-buttonsbutton1" onClick={toggleDropdown}>
                    <img
                      className="funnel-upload-04-icon"
                      alt=""
                      src={sliders02}
                    />
                    <div className="funnel-text-padding6" ref={dropdownRef}>
                      <div className="funnel-text65">
                        Sort By {selectedSortOption && `: ${selectedSortOption.label}`}
                      </div>
                      {isDropdownVisible && <SortByFilterComponent onOptionSelect={handleOptionSelect} />}
                    </div>
                    <img
                      className="funnel-placeholder-icon"
                      alt=""
                      src={placeholder2}
                    />

                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Listing Container */}
      <div className="funnel-spare-component-listing-container">

        {/* Listing layout start    */}
        {content}

      </div>

      {/* Load more button  */}
      {showLoadMore && (
        <div className="funnel-frame-wrapper44">
          <div className="funnel-frame-parent72">
            <div className="funnel-buttonsbutton-wrapper1">
              <button className='funnel-buttonsbutton-wrapper1-child'
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                <div className="funnel-buttonsbutton28">
                  <img
                    className="funnel-arrow-narrow-left-icon"
                    alt=""
                    src={arrowdown1}
                  />
                  <div className="funnel-search-input">
                    <div className="funnel-text146">{isFetching ? 'Loading...' : 'Load More'}</div>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

