import CryptoJS from 'crypto-js';



export const SECRET_KEY = '0123456789abcdef0123456789abcdef0123456789abcdef0123456789abcdef';

// export const encryptData = (data) => {
//     try {
//         console.log("Data before encrypting:", data);
//         const crypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
//         console.log("Data after encrypting:", crypted);
//         return crypted;
//     } catch (error) {
//         console.error('Encryption error:', error);
//     }
// };

// export const decryptData = (encryptedData) => {
//     const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
//     return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
// };

export function encryptData(data) {
  const dataString = JSON.stringify(data);
  return CryptoJS.AES.encrypt(dataString, SECRET_KEY).toString();
}


export function decryptUserData(ciphertext) {
  console.log("cipherText");
  console.log(ciphertext);
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext.data, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("DecryptedData");
    console.log(decryptedData);
    return decryptedData
  } catch (error) {
    ;
    console.log(error);
  }
  //Will check if response contains a object property status or not. if status then return directly.

  return;

}


export function decryptData(ciphertext) {
  console.log("cipherText");
  console.log(ciphertext);
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("DecryptedData");
    console.log(decryptedData);
    return decryptedData
  } catch (error) {
    ;
    console.log(error);
  }
  //Will check if response contains a object property status or not. if status then return directly.

  return;

}

export function decryptErrorData(ciphertext) {
  console.log("cipherText");
  console.log(ciphertext);
  try {
    const bytes = CryptoJS.AES.decrypt(ciphertext.data, SECRET_KEY);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    console.log("DecryptedData");
    console.log(decryptedData);
    return decryptedData
  } catch (error) {
    console.log(error);
  }
  //Will check if response contains a object property status or not. if status then return directly.

  return;

}


// export function decryptError(ciphertext) {
//   console.log("cipherText");
//   console.log(ciphertext);
//   try {
//     const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
//     const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//     console.log("DecryptedData");
//     console.log(decryptedData);
//     return decryptedData
//   } catch (error) {
//     console.log(error);
//   }
//   //Will check if response contains a object property status or not. if status then return directly.

//   return;

// }