import React, { useEffect, useRef, useState } from 'react';
import "../../screens/AdminDashboard/AdminDashboard.css";

import { Link, useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { firestore, doc, getDoc, auth, onAuthStateChanged, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "../../screens/Login/FirebaseClient";

import _1_2x from '../../icons/Gerator/dashboard/8595f4b711e503bc72fe396e5043e0c2-1@2x.png';

import { useLazyGetCurrentPlanOfUserQuery } from '../../api/PlanAPI';
import { useLazyGetProfileImageLinkQuery } from '../../api/Login&RegisterAPI';


import {AdminDashboardWantedDeviceComponent} from "../../components/AdminDashboard/AdminDashboardWantedDeviceComponent/AdminDashboardWantedDeviceComponent";
import {AdminDashboardWantedSpareComponent} from "../../components/AdminDashboard/AdminDashboardWantedSpareComponent/AdminDashboardWantedSpareComponent";
import {AdminDashboardWantedServiceComponent} from "../../components/AdminDashboard/AdminDashboardWantedServiceComponent/AdminDashboardWantedServiceComponent";
import {AdminDashboardWantedSoftwareComponent} from "../../components/AdminDashboard/AdminDashboardWantedSoftwareComponent/AdminDashboardWantedSoftwareComponent";

// const auth = getAuth(app);


export const AdminDashboardWantedCards = () => {

 

 


  const [currentUserMeta, setCurrentUserMeta] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const navigate = useNavigate();
  const [profileImageLink, setProfileImageLink] = useState("");
  const [currentPlanExpiry, setCurrentPlanExpiry] = useState("");
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [activeTab, setActiveTab] = useState('device');
  const [triggerGetCurrentPlan, { data: currentPlanData, isError: isCurrentPlanError, error: currentPlanError, isLoading: isCurrentPlanLoading, isSuccess: isCurrentPlanSuccess }] = useLazyGetCurrentPlanOfUserQuery();
  const [triggerNewProfileImageLink] = useLazyGetProfileImageLinkQuery();


  const renderContent = () => {
    switch (activeTab) {
      case 'device':
        return <AdminDashboardWantedDeviceComponent />;
      case 'spare':
        return <AdminDashboardWantedSpareComponent />;
      case 'service':
        return <AdminDashboardWantedServiceComponent />;
      case 'software':
        return <AdminDashboardWantedSoftwareComponent />;
 
     
      default:
        return null;
    }
  };

  // const [triggerGetUser, { data, error, isLoading, isError }] = useLazyGetUserQuery();
  useEffect(() => {
    // const auth = getAuth();

    // Listen for authentication state change
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {

        if (user) {
          // Get user to check wizardStep
          const userDocRef = doc(firestore, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const usermeta = userDocSnapshot.data(); // Default to 1 if not set

            if (usermeta.wizardStep !== 0) {
              navigate('/account-setup-check-email');
              return;
            }
            await triggerGetCurrentPlan().unwrap();
            setCurrentUser(user);
            setCurrentUserMeta(usermeta);
            // User is authenticated
          }
        }
      } else {
        // User is not authenticated, redirect to login
        navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
      }
    });




    return () => unsubscribe(); // Cleanup listener on component unmount
  }, [navigate]);

  useEffect(() => {
    const refreshProfileImage = async () => {
      if (currentUserMeta && currentUserMeta.profileImage.expiresAt < Date.now() / 1000) {
        // Pre-signed URL has expired, trigger a new request to the backend
        const newImageData = await triggerNewProfileImageLink().unwrap();
        // Update the profile image link directly
        setProfileImageLink(newImageData.url);
      } else if (currentUserMeta && currentUserMeta.profileImage.url) {
        // Use the existing pre-signed URL to display the profile image
        setProfileImageLink(currentUserMeta.profileImage.url);
      }
    };

    refreshProfileImage();
  }, [currentUserMeta]);

  // useEffect(() => {
  //   navigate('/sign-in', { state: { message: 'Please log in to continue.' } });
  // }, [isCurrentPlanError])



  useEffect(() => {
    if (isCurrentPlanSuccess && currentPlanData?.data?.order_plan?.id) {
      setCurrentPlanName(currentPlanData.data.order_plan.planName);
      setCurrentPlanExpiry(currentPlanData.data.orderExpiry);
    } else {
      console.log("No current plan data or ID not available.");
    }
  }, [isCurrentPlanSuccess, currentPlanData]);


  if (currentUser && isCurrentPlanSuccess && profileImageLink) {
    return (
    
              <>
                <div className="admin-dashboard-horizontal-tabs-wrapper">
                  <div className="admin-dashboard-horizontal-tabs">
                    <div className="admin-dashboard-tabs2">
                      <div className={activeTab === 'device' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-tab-name" style={{ cursor: "pointer" }} onClick={() => setActiveTab('device')}>Devices</div>
                      </div>
                      <div className={activeTab === 'spare' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text48" style={{ cursor: "pointer" }} onClick={() => setActiveTab('spare')}>Spares</div>
                      </div>
                      <div className={activeTab === 'service' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text49" style={{ cursor: "pointer" }} onClick={() => setActiveTab('service')}>Services</div>
                      </div>
                      <div className={activeTab === 'software' ? "dashboard-tab-button-base" : "dashboard-tab-button-base1"}>
                        <div className="admin-dashboard-text50" style={{ cursor: "pointer" }} onClick={() => setActiveTab('software')}>Software</div>
                      </div>
                    
                    
                    </div>
                  </div>
                </div>



                {renderContent()}

                </>

          





         


 
    )
  }
  else {
    return <Loader />
  }


}

