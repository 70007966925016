import React, { useState } from 'react';
import './TeamMemberTableTabs.css'; // Import your CSS file
import { ActiveTeamMemberTableComponent } from '../MemberComponent/ActiveTeamMemberTableComponent/ActiveTeamMemberTableComponent';
import { InvitedTeamMemberTableComponent } from '../MemberComponent/InvitedTeamMemberTableComponent/InvitedTeamMemberTableComponent';
import { InactiveTeamMemberTableComponent } from '../MemberComponent/InactiveTeamMemberTableComponent/InactiveTeamMemberTableComponent';

export const TeamMemberTableTabs = ({ currentUser, currentUsermeta }) => {
    const [activeButton, setActiveButton] = useState('Active');

    const handleButtonClick = (switchButton) => {
        setActiveButton(switchButton);
    };

    const renderComponent = () => {
        switch (activeButton) {
            case 'Active':
                return <ActiveTeamMemberTableComponent currentUser={currentUser} currentUsermeta={currentUsermeta} />;
            case 'Pending':
                return <InvitedTeamMemberTableComponent currentUser={currentUser} currentUsermeta={currentUsermeta} />;
            case 'Inactive':
                return <InactiveTeamMemberTableComponent currentUser={currentUser} currentUsermeta={currentUsermeta} />;
            default:
                return null;
        }
    };


    return (
        <div className='team-display-main-container'>
            <div className='team-display-tab'>
                <div className="team-display-button-group custom-cursor">
                    <div
                        className={`team-display-button-group-base1 ${activeButton === 'Active' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Active')}
                    >
                        <div className="team-display-text31">Active</div>
                    </div>
                    <div
                        className={`team-display-button-group-base1 ${activeButton === 'Pending' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Pending')}
                    >
                        <div className="team-display-text32">Pending</div>
                    </div>
                    <div
                        className={`team-display-button-group-base1 ${activeButton === 'Inactive' ? 'active' : ''}`}
                        onClick={() => handleButtonClick('Inactive')}
                    >
                        <div className="team-display-text29">Inactive</div>
                    </div>
                </div>

            </div>

            <div className="component-display-area">
                {renderComponent()}
            </div>

        </div>
    );
};


export default TeamMemberTableTabs;
