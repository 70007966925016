import React, { useEffect, useState } from 'react';
import "./ThreeDotsListingComponent.css";
import divider from '../../icons/Gerator/dashboard/divider.svg';
import { Link } from 'react-router-dom';
import { useUpdateMemberStatusRejectToActiveMutation, useUpdateMemberStatusToAcceptMutation, useUpdateMemberStatusToActiveMutation, useUpdateMemberStatusToInactiveMutation, useUpdateMemberStatusToRejectMutation, useUpdateMemberStatusToRevokeMutation } from '../../api/MemberAPI';

export const ThreeDotsMemberComponent = ({ option, userId, onClose }) => {

  const [updateToInactive] = useUpdateMemberStatusToInactiveMutation();

  const [updateToActive] = useUpdateMemberStatusToActiveMutation();

  const [updateToAccept] = useUpdateMemberStatusToAcceptMutation();

  const [updateToReject] = useUpdateMemberStatusToRejectMutation();

  const [updateToRejectActive] = useUpdateMemberStatusRejectToActiveMutation();

  const [updateToRevoke] = useUpdateMemberStatusToRevokeMutation();



  const handleInactiveUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await updateToInactive({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleActiveUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await updateToActive({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleAcceptUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await updateToAccept({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleRejectUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await updateToReject({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleMakeRejectActiveUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await updateToRejectActive({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleRevokeUpdate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      if (!userId) {
        console.error('No user ID provided');
        return;
      }

      console.log('Updating status for user:', userId);
      const response = await updateToRevoke({ id: userId }).unwrap();
      console.log('Update response:', response);

      if (response.status) {
        onClose();
        window.location.reload();
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };



  const handleItemClick = (e, action) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('Action clicked:', action);

    switch (action) {
      case 'active':
        handleActiveUpdate(e);
        break;
      case 'inactive':
        handleInactiveUpdate(e);
        break;
      case 'accept':
        handleAcceptUpdate(e);
        break;
      case 'reject':
        handleRejectUpdate(e);
        break;
      case 'rejectActive':
        handleMakeRejectActiveUpdate(e);
        break;
      case 'revoke':
        handleRevokeUpdate(e);
        break;
      // Add other cases as needed
      default:
      // handleRejectUpdate(e);
    }
  };

  // Reusable menu item component with proper event handling
  const MenuItem = ({ onClick, text, to, action }) => (
    <div
      className="three-dots-filter-dropdown-dropdown-list-item"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (onClick) onClick(e);
        if (action) handleItemClick(e, action);
      }}
    >
      <div className="three-dots-filter-dropdown-content48">
        <div className="three-dots-filter-dropdown-icon-and-text">
          {to ? (
            <Link
              className="three-dots-filter-dropdown-text247"
              to={to}
              onClick={(e) => e.stopPropagation()}
            >
              {text}
            </Link>
          ) : (
            <div className="three-dots-filter-dropdown-text247">{text}</div>
          )}
        </div>
      </div>
    </div>
  );

  if (userId) {
  }
  return (
    <div
      className="three-dots-filter-dropdown-three-dots-filter-all-options-fixed"
      onClick={(e) => e.stopPropagation()}
    >
      <section className="three-dots-filter-dropdown-menu-items">
        {option === 'inactive' && (
          <MenuItem
            text="Active"
            action="active"
          />
        )}

        {option === 'active' && (
          <MenuItem
            text="Inactive"
            action="inactive"
          />
        )}

        {option === 'invite' && (
          <MenuItem
            text="Revoke"
            action="revoke"
          />
        )}
        {/* if backend invitation status is reject then show active option and make it */}
        {option === 'reject' && (
          <MenuItem
            text="Active"
            action="rejectActive"
          />
        )}

        {option === 'request' && (
          <>
            <MenuItem text="Accept" action="accept" />
            <MenuItem text="Reject" action="reject" />
          </>
        )}

        {!['inactive', 'active', 'invite', 'reject', 'request'].includes(option) && (
          <>
            <MenuItem text="Active" to={`/edit-${option}`} />
            <MenuItem text="Inactive" to={`/archive-${option}`} />
            <MenuItem text="Accept" to={`/delete-${option}`} />
            <MenuItem text="Reject" to={`/delete-${option}`} />
          </>
        )}
      </section>
    </div>
  );
};

export default ThreeDotsMemberComponent;