import React, { useEffect, useRef, useState } from 'react';
import "./AddTeamSlideOutForm.css";
import xclose from "../../icons/Gerator/slide_out/xclose.svg";
import help_icon from "../../icons/Gerator/slide_out/help-icon.svg";
import mail01 from "../../icons/Gerator/slide_out/mail01.svg";
import invite2 from "../../icons/Gerator/slide_out/invite2.svg";
import { Formik, useField } from 'formik';
import { Form } from 'react-router-dom';
import * as Yup from "yup";
import { ErrorShowComponent } from '../ErrorShowComponent/ErrorShowComponent';
import { decryptErrorData } from '../../helper/AESHelper';
import { useAddInviteMemberMutation } from '../../api/MemberAPI';
import { objectToMemberData } from '../../helper/MemberHelper';



const MyTextInput = ({ validate, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <>
      <div className="add-team-input-field">
        <label className="add-team-label9" htmlFor={props.id || props.name}>
          {label} <span className="add-device-step-span">*</span>
        </label>
        {/* <div className="add-team-label9">Full Name</div> */}
        <div className="add-team-input6">
          <input
            className="add-team-content72"
            // placeholder="Olivia Rhye"
            // type="text"
            {...field}
            {...props}
          />

          <img
            className="add-team-help-icon2"
            alt=""
            src={help_icon}
          />
        </div>
      </div>
      {/* <div className="add-team-hint-text6">This is a hint text to help user.</div> */}
      {/* <div className="account-update-profile-input-with-label3">
        <div className="account-update-profile-input3">

          <input
            // className="account-update-profile-content27"
            {...field}
            {...props}
          />
          <img
            className="account-update-profile-help-icon"
            alt=""
            src={help_icon}
          />
        </div>
      </div> */}
      {(meta.touched && meta.error) ? (
        <div className="account-update-profile-hint-text2 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="account-update-profile-hint-text2 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}

    </>
  );
};

const MyCustomEmailInput = ({ validate, domain, label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  let fieldValidationData = null;
  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }

  return (
    <> <div className="add-team-input-field1">
      <label className="add-team-label10" htmlFor={props.id || props.name}>
        {label} <span className="add-device-step-span">*</span>
      </label>
      {/* <div className="add-team-label10">Email address</div> */}
      <div className='add-team-email-card'>
        <div className="add-team-input7">
          <div className="add-team-content73">
            <img
              className="add-team-mail-01-icon"
              alt=""
              src={mail01}
            />

            <input
              className="add-team-text274"
              // placeholder="Olivia Rhye"
              // type="text"
              {...field}
              {...props}
            />
            <img
              className="add-team-help-icon3"
              alt=""
              src={help_icon}
            />
          </div>

        </div>
        <div className="add-team-email-domain">
          @{domain}
        </div>
      </div>

    </div>

      {(meta.touched && meta.error) ? (
        <div className="account-update-profile-hint-text2 error-color">
          {meta.error}
        </div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="account-update-profile-hint-text2 error-color">
          {fieldValidationData.msg}
        </div>
      ) : null}

    </>
  );
};

const MySelect = ({ validate, label, ...props }) => {
  const [field, meta] = useField(props);

  const inputStyle = meta.touched && meta.error ? { borderColor: 'red' } : {};   //New code for border colour

  let fieldValidationData = null;
  // console.log("MySelect inside validation result");
  // console.log(validate);

  if (validate?.length > 0) {
    validate.some(field => { // Use 'some' for early termination
      if (field.path === props.name) {
        fieldValidationData = field;
        return true; // This will stop the loop early
      }
      return false; // Continue if no match
    });
  }


  return (
    <>
      <div className="add-team-input-field">
        <label className="add-team-label12" htmlFor={props.name}>
          {label} <span className="add-device-step-span">*</span>
        </label>
        <select
          className="add-team-input6a add-device-step-first-nested-input account-update-profile-content-custom-23"
          // style={inputStyle}   //New code for border colour
          {...field}
          {...props}
        />
      </div>
      {(meta.touched && meta.error) ? (
        <div className="error-for-all-field">{meta.error}</div>
      ) : null}

      {fieldValidationData !== null ? (
        <div className="error-for-all-field">{fieldValidationData.msg}</div>
      ) : null}
    </>
  );
};

export const AddTeamSlideOutForm = ({ onClose, domain, userType }) => {

  const [backendValidation, setBackendValidation] = useState([])
  const formikRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    message: "",
    color: "",
  });
  const [addInviteMember, { isSuccess, isLoading, isError, error }] = useAddInviteMemberMutation();


  const [initialValues, setInitialValues] = useState({
    displayName: '',
    email: '',
    userWorkTitle: '',
    userType: '',
    memberInvitationStatus: 3,
  });

  useEffect(() => {
    console.log(userType);

  }, [userType])


  const finalSubmit = async (formData, setSubmitting) => {


    try {

      const response = await addInviteMember(formData).unwrap();
      //If form was submitted successfully then status return with true, then reseting form
      console.log(response);
      if (response.status) {
        console.log(response);

        // setShowSuccessMessage(response.message)
        window.location.reload();
        formikRef.current.resetForm();
        // onClose();
      }
      // if (!response.status) {
      //   setShowSuccessMessage({ message: response.message, color: "red" })
      // }
    }
    catch (error) {
      if (error.status === 422) {
        console.log(error);
        const decryptedData = decryptErrorData(error);
        console.log(decryptedData);
        setBackendValidation(decryptedData.errors);
        // setShowSuccessMessage({ message: decryptedData.message, color: "red" });
      }
      else if (error.status === 401) {
        console.log(error);
        if (error.data.message) {
          setShowSuccessMessage({ message: error.data.message, color: "red" });
        } else {
          setShowSuccessMessage({ message: 'An unknown error occurred. Please try again later.', color: "red" });
        }

      } else {
        console.log(error);
      }
      setSubmitting(false);
    }

  };



  if (domain && userType?.isSuccess) {
    return (

      <>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={Yup.object({

            displayName: Yup.string()
              .required('Field is required')
              .min(3, 'Field must be at least 3 characters')
              .max(50, 'Field must be at most 50 characters')
              .test(
                'is-full-name',
                'Please enter your full name (first and last name).',
                (value) => {
                  if (!value) return false; // This ensures required validation is not bypassed.
                  const nameParts = value.trim().split(/\s+/);
                  return nameParts.length >= 2;
                }
              ),

            email: Yup.string()
              .required("This is a required field")
              .max(30, "Must be 30 characters or less")
              .test(
                "is-username",
                "Please add only the username, not the whole email address (e.g., 'example' instead of 'example@email.com').",
                (value) => {
                  if (!value) return true; // Skip validation for empty fields (handled by `.required()`)
                  return !value.includes("@"); // Ensure the input doesn't contain '@'
                }
              ),
            userWorkTitle: Yup.string()
              .required("Field is required")
              .matches(/^[a-zA-Z][A-Za-z\s\-\.]+$/, 'Must start with a letter and contain only letters, spaces, hyphens, and dots')
              .min(3, "Must be 3 characters or more")
              .max(25, "Must be 25 characters or less"),
            userType: Yup.string().required("This is a required field").test(
              "OPTION", "Please select a valid option",
              (value) => value !== '0'
            ),

          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values);
            // multiPartData = ;
            finalSubmit(objectToMemberData(values, domain), setSubmitting);
            // resetForm();
            // setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="account-update-profile-content-elements">
              <div className="add-team-slide-out-menu">
                <main className="add-team-panel">
                  <section className="add-team-slide-out-menu-header-parent">
                    <header className="add-team-slide-out-menu-header">
                      <div className="add-team-content71">
                        <img
                          className="add-team-featured-icon2"
                          loading="lazy"
                          alt=""
                          src={invite2}
                        />

                        <div className="add-team-text-and-supporting-text26">
                          <a className="add-team-title9">Invite People</a>
                          {/* <div className="add-team-supporting-text91">
                    This will appear in your funnel
                  </div> */}
                        </div>
                      </div>
                      <div className="add-team-button-close-x6" onClick={onClose} >
                        <img
                          className="add-team-mail-01-icon"
                          loading="lazy"
                          alt=""
                          src={xclose}
                        />
                      </div>
                    </header>
                    <div className="add-team-section-container">
                      <div className="add-team-section2">
                        <div className="add-team-input-field">
                          {/* <div className="add-team-input-field">
                    <div className="add-team-label9">Full Name</div>
                    <div className="add-team-input6">
                      <input
                        className="add-team-content72"
                        placeholder="Olivia Rhye"
                        type="text"
                      />
  
                      <img
                        className="add-team-help-icon2"
                        alt=""
                        src={help_icon}
                      />
                    </div>
                  </div>
                  <div className="add-team-hint-text6">This is a hint text to help user.</div> */}
                          <MyTextInput
                            label="Full Name"
                            name="displayName"
                            id="displayName"
                            type="text"
                            validate={backendValidation}
                            placeholder="Enter first and last name"
                          />

                        </div>
                        <div className="add-team-form">
                          <div className="add-team-input-field1">
                            {/* <div className="add-team-input-field1">
                              <div className="add-team-label10">Email address</div>
                              <div className='add-team-email-card'>
                                <div className="add-team-input7">
                                  <div className="add-team-content73">
                                    <img
                                      className="add-team-mail-01-icon"
                                      alt=""
                                      src={mail01}
                                    />
  
                                    <input
                                      className="add-team-text274"
                                      placeholder="olivia.rhye"
                                      type="text"
                                    />
                                    <img
                                      className="add-team-help-icon3"
                                      alt=""
                                      src={help_icon}
                                    />
                                  </div>
  
                                </div>
                                <div className="add-team-email-domain">
                                  @{domain}
                                </div>
                              </div>
  
                            </div> */}
                            <MyCustomEmailInput
                              label="Email address"
                              name="email"
                              id="email"
                              type="text"
                              validate={backendValidation}
                              domain={domain}
                              placeholder="Enter username"
                            />
                            {/* <div className="add-team-hint-text6">
                    @refurbish-med.com
                    </div> */}
                          </div>
                        </div>
                        <div className="add-team-input-field">
                          {/* <div className="add-team-input-field">
                            <div className="add-team-label11">Work Title</div>
                            <div className="add-team-input6">
                              <input
                                className="add-team-content72"
                                placeholder="Sales Manager"
                                type="text"
                              />
  
                              <img
                                className="add-team-help-icon2"
                                alt=""
                                src={help_icon}
                              />
                            </div>
                          </div>
                          <div className="add-team-hint-text6">This is a hint text to help user.</div> */}
                          <MyTextInput
                            label="Work Title"
                            name="userWorkTitle"
                            id="userWorkTitle"
                            type="text"
                            validate={backendValidation}
                            placeholder="Enter work title"
                          />
                        </div>
                        <div className="add-team-input-field">
                          {/* <div className="add-team-input-field">
                            <div className="add-team-label12">Role <span className="add-device-step-span">*</span></div>
                            <select className="add-team-input6a">
  
                              <option className="add-team-content72" value="teamMember">Team Member</option>
                              <option className="add-team-content72" value="coOwner">Co-owner</option>
  
                            </select>
                          </div>
                          <div className="add-team-hint-text6">This is a hint text to help user.</div> */}
                          {/* <MySelect label="Role" validate={backendValidation} name="userType" id="userType" >
                            <option value="0">Select From Dropdown list </option>
                            {(userType.isLoading || userType.isError) && <option value="0">Loading...</option>}
                            {
                              (userType.isSuccess) && (userType.data.map(tax => (
                                <option key={tax.id} value={tax.id}>{tax.name}</option>
                              )))
                            }
                          </MySelect> */}

                          <MySelect label="Role" validate={backendValidation} name="userType" id="userType">
                            <option value="0">Select From Dropdown list</option>
                            {userType.isLoading && <option value="0">Loading...</option>}
                            {userType.isError && <option value="0">Failed to load options</option>}
                            {userType.isSuccess && userType.data.length === 0 && (
                              <option value="0">No roles available</option>
                            )}
                            {userType.isSuccess &&
                              userType.data.map((tax) => (
                                <option key={tax.id} value={tax.id}>
                                  {tax.name}
                                </option>
                              ))}
                          </MySelect>

                        </div>
                      </div>
                    </div>
                    {showSuccessMessage.message && (
                      <div
                        style={{
                          fontSize: "14px",
                          color: showSuccessMessage.color,
                          padding: "15px 10px",
                          border: "1px solid " + showSuccessMessage.color,
                          borderRadius: "10px",
                          margin: "0 auto",
                        }}
                      >
                        {showSuccessMessage.message}
                      </div>
                    )}
                    <div className="add-team-divider-wrapper">
                      <div className="add-team-divider12"></div>
                    </div>
                  </section>
                  <footer className="add-team-footer2">
                    <div className="add-team-content76">
                      <div className="add-team-actions19">
                        <button className="add-team-buttonsbutton49" onClick={onClose}>
                          <img
                            className="add-team-placeholder-icon91"
                            alt=""
                            src="./public/placeholder2.svg"
                          />

                          <div className="add-team-text-padding51">
                            <div className="add-team-text275">Cancel</div>
                          </div>
                          <img
                            className="add-team-placeholder-icon91"
                            alt=""
                            src="./public/placeholder2.svg"
                          />
                        </button>
                        <button className="add-team-buttonsbutton50" type='submit' onClick={handleSubmit} disabled={isSubmitting}>
                          <img
                            className="add-team-placeholder-icon91"
                            alt=""
                            src="./public/placeholder3.svg"
                          />

                          <div className="add-team-text-padding51">
                            <div className="add-team-text276">{isSubmitting || isSuccess ? 'Confirming...' : 'Confirm'}</div>
                          </div>
                          <img
                            className="add-team-placeholder-icon91"
                            alt=""
                            src="./public/placeholder3.svg"
                          />
                        </button>
                      </div>
                    </div>
                  </footer>
                </main>
              </div>
            </Form>
          )}
        </Formik >

      </>
    )
  } else if (userType?.isError) {
    return <ErrorShowComponent message1={'Internal server error'} message2={'Theres is an issue while loading required data.'} />
  }
}

